import './info-view.scss'
import React, { FC, useEffect, useRef, useState } from 'react'
import get from 'lodash/get'
import XLabelValue from '../components/XLabelValue'
import { htmlToString } from '../utils/general-util'
import ReactDOMServer from 'react-dom/server'
import { Card } from 'primereact/card'
import Info from './info'
import { version, buildDate } from './info.json'
import InfoService from './info-service'
import ServerInfo from './server-info'
import { Subscription } from 'rxjs'
import TranslateService from '../services/translate-service'

const InfoView: FC = () => {
  const [info, setInfo] = useState<Info | undefined>()
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [filterText, setFilterText] = useState('')
  const key = useRef<number>(1)

  const addSub = (sub: Subscription) => {
    setSubscriptions([sub, ...subscriptions])
  }

  /**
   * Info Dialog configuration
   */
  useEffect(() => {
    addSub(
      InfoService.loadBackendInfo().subscribe((response: any) => {
        const serverInfo: ServerInfo = response.data
        setInfo({
          frontendVersion: version,
          frontendBuildTime: buildDate,
          backendVersion: serverInfo.buildVersion,
          backendBuildTime: serverInfo.buildTime,
        })
      })
    )
    // clean up the subscriptions on-destroy
    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe())
    }
  }, [])

  function getValue(fieldName: string) {
    return get(info, fieldName)
  }

  function basicInfo() {
    return [
      labelFieldName('Frontend Version', 'frontendVersion'),
      labelFieldName('Frontend Build Time', 'frontendBuildTime'),
      labelFieldName('Backend Version', 'backendVersion'),
      labelFieldName('Backend Build Time', 'backendBuildTime'),
    ]
  }

  function labelFieldName(
    label: string,
    fieldName: string,
    renderer?: (e: any) => any,
    linkDestination?: string,
    displayLink?: boolean
  ) {
    let value = getValue(fieldName)
    if (renderer) {
      value = renderer(value)
    }
    if (React.isValidElement(value)) {
      value = htmlToString(ReactDOMServer.renderToString(value))
    }
    if (displayLink) {
      return (
        <XLabelValue
          label={label}
          value={value}
          linkDestination={linkDestination}
          filterText={filterText}
          key={key.current++}
        />
      )
    } else {
      return <XLabelValue label={label} value={value} filterText={filterText} key={key.current++} />
    }
  }

  return (
    <div className="info-view">
      {/*to be used in case filtering is required*/}
      {/*<span className="p-input-icon-left">*/}
      {/*  <i className="pi pi-search" />*/}
      {/*  <InputText*/}
      {/*    className="p-d-block p-mb-2"*/}
      {/*    value={filterText}*/}
      {/*    onChange={(e) => setFilterText(e.target.value)}*/}
      {/*    placeholder="Filter..."*/}
      {/*  />*/}
      {/*</span>*/}
      <Card title={TranslateService.translate('Build:')}>{basicInfo()}</Card>
    </div>
  )
}
export default InfoView
