import { lazy } from 'react'
import { GRoute } from '../../models/Route'
import { AUTHORITIES } from '../../auth/authorities'

const KreditzusagenEdit = lazy(() => import('./kreditzusagen-edit'))
const KreditzusagenList = lazy(() => import('./kreditzusagen-list'))

const kreditzusagenRoutes: GRoute[] = [
  {
    path: '/kreditzusagen/erstellen',
    component: KreditzusagenEdit,
    auth: AUTHORITIES.KREDITZUSAGEN__ANLEGEN,
  },
  {
    path: '/kreditzusagen/editieren/:id',
    component: KreditzusagenEdit,
    auth: AUTHORITIES.KREDITZUSAGEN__EDITIEREN_OPTION,
  },
  {
    path: '/kreditzusagen/auflisten',
    component: KreditzusagenList,
    auth: AUTHORITIES.KREDITZUSAGEN__MEINE_LISTE,
  },
]

export default kreditzusagenRoutes
