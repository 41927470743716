import React from 'react'
import { Redirect } from 'react-router-dom'
import homeRoutes from './features/home/home-routes'
import stammdatenRoutes from './features/stammdaten/stammdaten-routes'
import GRoute from './models/Route'
import { isObjectEmpty } from './utils/@utils'
import reportRoutes from './features/reports/report-routes'
import { AUTH_ROLES } from './auth/authRoles'
import AuthGuard from './auth/AuthGuard'
import kreditzusagenRoutes from './features/kreditzusagen/kreditzusagen-routes'
import buergschaftRoutes from './features/buergschaft/buergschaft-routes'
import kundeRoutes from './features/kunde/kunde-routes'
import incomingAvalRoutes from './features/buergschaft/incoming/incoming-aval-routes'
import buergschaftsanfrageRoutes from './features/buergschaftsanfragen/buergschaftsanfrage-routes'
import projektRoutes from './features/projekt/projekt-routes'
import konzernRahmenRoutes from './features/kreditzusagen/konzern/konzern-rahmen-routes'

const errorRoute: GRoute[] = [
  {
    auth: AUTH_ROLES.GUEST,
    component: () => <Redirect to="/home" />,
  },
]

const routes: GRoute[] = [
  {
    path: '/',
    component: AuthGuard,
    routes: [
      ...homeRoutes,
      ...incomingAvalRoutes,
      ...buergschaftsanfrageRoutes,
      ...buergschaftRoutes,
      ...kreditzusagenRoutes,
      ...konzernRahmenRoutes,
      ...kundeRoutes,
      ...reportRoutes,
      ...stammdatenRoutes,
      ...projektRoutes,
      ...errorRoute,
    ],
  },
]

const routeToRoles = (
  _routes: GRoute[],
  _routeToRolesMap: Record<string, string[]>,
  _routesWithPlaceHolders: string[]
) => {
  if (!_routes) {
    return
  }
  _routes.forEach((_route) => {
    if (_route.path) {
      _routeToRolesMap[_route.path] = _route.auth
      if (_route.path.includes(':')) {
        _routesWithPlaceHolders.push(_route.path)
      }
    }
    if (_route.routes) {
      routeToRoles(_route.routes, _routeToRolesMap, _routesWithPlaceHolders)
    }
  })
}

const routeToRolesMap: Record<string, string[]> = {}
const routesWithPlaceHolders: string[] = []
if (isObjectEmpty(routeToRolesMap)) {
  routeToRoles(routes, routeToRolesMap, routesWithPlaceHolders)
}

export const getRolesForRoute: (path: string) => string[] | null = (path) => {
  if (!path) {
    return null
  }

  // remove a possible slash from the end
  if (path.slice(-1) === '/') {
    path = path.slice(0, -1)
  }

  const roles = routeToRolesMap[path]

  if (roles) {
    return roles
  } else {
    const matchedRoute: string | undefined = routesWithPlaceHolders.find((route) => {
      const routeParts: string[] = route.split('/')
      const pathParts: string[] = path.split('/')
      if (routeParts.length !== pathParts.length) {
        return false
      } else {
        return routeParts.every((routePart, index) => {
          return routePart.includes(':') || routePart === pathParts[index]
        })
      }
    })

    if (matchedRoute) {
      return routeToRolesMap[matchedRoute]
    } else {
      return null
    }
  }
}

export default routes
