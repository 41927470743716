import { lazy } from 'react'
import { AUTH_ROLES } from 'app/auth/authRoles'
import { GRoute } from '../../../models/Route'
import { AUTHORITIES } from '../../../auth/authorities'

const VersichererEdit = lazy(() => import('./versicherer-edit'))
const VersichererList = lazy(() => import('./versicherer-list'))

const versichererRoutes: GRoute[] = [
  {
    path: '/stammdaten/versicherer/erstellen',
    component: VersichererEdit,
    auth: AUTHORITIES.STAMMDATEN__VERSICHERER_ANLEGEN_MAINBUTTON,
  },
  {
    path: '/stammdaten/versicherer/editieren/:id',
    component: VersichererEdit,
    auth: AUTHORITIES.STAMMDATEN__VERSICHERER_EDITIEREN_OPTION,
  },
  {
    path: '/stammdaten/versicherer/auflisten',
    component: VersichererList,
    auth: AUTHORITIES.STAMMDATEN__VERSICHERER_LISTE,
  },
]

export default versichererRoutes
