import './Header.scss'
import React, { useEffect, useRef, useState } from 'react'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import DropdownMenu from 'react-bootstrap/DropdownMenu'
import DropdownToggle from 'react-bootstrap/DropdownToggle'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setLayoutSettings } from 'app/redux/actions/LayoutActions'
import { useAuth0 } from '@auth0/auth0-react'
import { merge } from 'lodash'
import localStorageService from '../services/localStorageService'
import AuthService from '../services/auth-service'
import InfoService from '../info/info-service'
import ConfigService from '../config/config-service'
import LanguageSwitcherSelector from './language-switcher'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Button } from 'primereact/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { AUTHORITIES } from '../auth/authorities'
import TranslateService from '../services/translate-service'
import { Subscription } from 'rxjs'
import KonzernSelect from './konzern-select'

const Header = () => {
  const [userLogoUrl, setUserLogoUrl] = useState<string>(undefined)
  const [userName, setUserName] = useState<string>('')
  const [language, setLanguage] = useState<string | undefined>()
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const { logout } = useAuth0()
  const dispatch = useDispatch()
  const kontaktToggle = useRef(null)

  const addSub = (sub: Subscription) => {
    setSubscriptions([sub, ...subscriptions])
  }

  useEffect(() => {
    const user = localStorageService.getItem('auth_user')
    if (user && user.picture) {
      setUserLogoUrl(user.picture)
      setUserName(user.name)
    }
    addSub(
      TranslateService.language$.subscribe((_language: string) => {
        if (_language) {
          TranslateService.language = _language
          setTimeout(() => setLanguage(_language), 0)
        }
      })
    )
  }, [language])

  const { settings } = useSelector(
    (state) => ({
      settings: state.layout.settings,
    }),
    shallowEqual
  )

  const handleMenuClick = () => {
    setLayoutSettings(
      merge({}, settings, {
        layoutSettings: {
          leftSidebar: {
            open: !settings.layoutSettings.leftSidebar.open,
            secondaryNavOpen: !settings.layoutSettings.leftSidebar.open,
          },
        },
      })
    )(dispatch)
  }

  const toggleFullScreen = () => {
    if (document.fullscreenEnabled) {
      if (!document.fullscreen) document.documentElement.requestFullscreen()
      else document.exitFullscreen()
    }
  }

  const logoutUser = () => {
    AuthService.removeUser()
    logout()
  }

  const displayInfoDialog = () => {
    InfoService.displayInfoDialog()
  }

  if (!AuthService.hasAuthority(AUTHORITIES.HEADER)) {
    return (
      <div>
        {TranslateService.translate(
          'Sie haben nicht die erforderlichen Zugriffsrechte für diese Seite.'
        )}
      </div>
    )
  }

  return (
    <div className="main-header">
      <div className="logo">
        <img src="/assets/images/logo.png" alt="" />
      </div>

      <div className="menu-toggle" onClick={handleMenuClick}>
        <div />
        <div />
        <div />
      </div>

      <p className={'pt-3 gracher-willkommen'}>
        {TranslateService.translate('Willkommen im Gracher SuretyManager')}
      </p>

      <div style={{ margin: 'auto' }} />

      {AuthService.hasAuthority(AUTHORITIES.STAFF) && <KonzernSelect />}

      <div style={{ margin: 'auto' }} />

      <div className="header-part-right">
        <Button
          label={TranslateService.translate('Kontakt')}
          className="p-button-text gracher-info-button"
          onClick={(e) => kontaktToggle.current.toggle(e)}
        />
        <div>
          <span>
            <LanguageSwitcherSelector />
          </span>
        </div>
        <OverlayPanel ref={kontaktToggle}>
          <div className="gracher-info">
            <div className="pb-2 p-text-bold">
              {TranslateService.translate('Gracher-Aval-Management')}
            </div>
            <div>
              <span className="info-column-1">
                <i className="pi pi-phone" />
                <span className="pl-2 p-text-bold">{TranslateService.translate('Telefon:')}</span>
              </span>
              <a href="tel:+4965198127230">0651-98127230</a>
              <span className="pl-2">
                {TranslateService.translate('Geschäftszeit von 9:00 bis 17:00 Uhr')}
              </span>
            </div>
            <div>
              <span className="info-column-1">
                <a href="mailto:avale@gracher.de">
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
                <span className="pl-2 p-text-bold">{TranslateService.translate('E-Mail:')}</span>
              </span>
              <a href="mailto: avale@gracher.de">avale@gracher.de</a>
            </div>
          </div>
        </OverlayPanel>

        <OverlayTrigger
          key={'toggleFullScreen'}
          placement={'bottom'}
          overlay={
            <Tooltip id={'toggleFullScreen'}>
              {TranslateService.translate('Vollbildmodus umschalten')}
            </Tooltip>
          }
        >
          <i
            className="im i-Full-Screen header-icon d-none d-sm-inline-block"
            onClick={toggleFullScreen}
          />
        </OverlayTrigger>

        <div className="user col align-self-end">
          <Dropdown>
            <DropdownToggle variant="link" className="toggle-hidden">
              <img
                src={userLogoUrl}
                id="userDropdown"
                alt=""
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              />
            </DropdownToggle>
            <DropdownMenu>
              <div className="dropdown-header">
                <i className="im i-Lock-User mr-1" /> {userName}
              </div>
              <span className="dropdown-item cursor-pointer" onClick={() => logoutUser()}>
                {TranslateService.translate('Ausloggen')}
              </span>
              <span className="dropdown-item cursor-pointer" onClick={() => displayInfoDialog()}>
                {TranslateService.translate('Info')}
              </span>
              <span
                className="dropdown-item cursor-pointer"
                onClick={() => ConfigService.displayConfigDialog()}
              >
                {TranslateService.translate('Konfiguration')}
              </span>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

export default Header
