import { lazy } from 'react'
import { AUTH_ROLES } from 'app/auth/authRoles'
import { GRoute } from '../../models/Route'
import {AUTHORITIES} from "../../auth/authorities";

const KundeEdit = lazy(() => import('./kunde-edit'))
const KundeList = lazy(() => import('./kunde-list'))

const kundeRoutes: GRoute[] = [
  {
    path: '/firma/erstellen',
    component: KundeEdit,
    auth: AUTHORITIES.STAMMDATEN__KUNDENDATEN_ANLEGEN_MAINBUTTON,
  },
  {
    path: '/firma/edit/:id',
    component: KundeEdit,
    auth: AUTHORITIES.STAMMDATEN__KUNDENDATEN_EDITIEREN_OPTION,
  },
  {
    path: '/firma/auflisten',
    component: KundeList,
    auth: AUTHORITIES.STAMMDATEN__KUNDENDATEN_LISTE,
  },
]

export default kundeRoutes
