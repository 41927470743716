export const SET_LAYOUT_SETTINGS = 'LAYOUT_SET_SETTINGS'

export const setLayoutSettings = (data) => (dispatch) => {
  //console.log("data", data)
  dispatch({
    type: SET_LAYOUT_SETTINGS,
    data,
  })
}

