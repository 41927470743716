export const AUTHORITIES = {
  HEADER: 'header',
  FOOTER: 'footer',
  HOME__LINK: 'home:link',
  HOME__MEINE_AVALSTATUS_TABLE: 'home:meine-avalstatus-table',
  HOME__AVALSTATUS_TABLE: 'home:avalstatus-table',
  HOME__AVALANFRAGEN_LINK: 'home:avalanfragen-link',
  HOME__AVALUEBERSICHT_LINK: 'home:avaluebersicht-link',
  HOME__AVALLINIENAUSLASTUNG_TABLE: 'home:avallinienauslastung-table',
  HOME__MEINE_DETAILANSICHT_TABLE: 'home:meine-detailansicht-table',
  HOME__DETAILANSICHT_TABLE: 'home:detailansicht-table',
  STAMMDATEN__BRANCHEN_LISTE: 'stammdaten:branchen-liste',
  STAMMDATEN__BRANCHEN_ANLEGEN_MAINBUTTON: 'stammdaten:branchen-anlegen-mainbutton',
  STAMMDATEN__BRANCHEN_EDITIEREN_OPTION: 'stammdaten:branchen-editieren-option',
  STAMMDATEN__BRANCHEN_LOESCHEN_OPTION: 'stammdaten:branchen-loeschen-option',
  STAMMDATEN__AVALARTEN_LISTE: 'stammdaten:avalarten-liste',
  STAMMDATEN__AVALARTEN_ANLEGEN_MAINBUTTON: 'stammdaten:avalarten-anlegen-mainbutton',
  STAMMDATEN__AVALARTEN_EDITIEREN_OPTION: 'stammdaten:avalarten-editieren-option',
  STAMMDATEN__AVALARTEN_LOESCHEN_OPTION: 'stammdaten:avalarten-loeschen-option',
  STAMMDATEN__GERICHTSSTAENDE_LISTE: 'stammdaten:gerichtsstaende-liste',
  STAMMDATEN__GERICHTSSTAENDE_ANLEGEN_MAINBUTTON: 'stammdaten:gerichtsstaende-anlegen-mainbutton',
  STAMMDATEN__GERICHTSSTAENDE_EDITIEREN_OPTION: 'stammdaten:gerichtsstaende-editieren-option',
  STAMMDATEN__GERICHTSSTAENDE_LOESCHEN_OPTION: 'stammdaten:gerichtsstaende-loeschen-option',
  STAMMDATEN__VERSICHERER_LISTE: 'stammdaten:versicherer-liste',
  STAMMDATEN__VERSICHERER_ANLEGEN_MAINBUTTON: 'stammdaten:versicherer-anlegen-mainbutton',
  STAMMDATEN__VERSICHERER_EDITIEREN_OPTION: 'stammdaten:versicherer-editieren-option',
  STAMMDATEN__VERSICHERER_LOESCHEN_OPTION: 'stammdaten:versicherer-loeschen-option',
  STAMMDATEN__KUNDENDATEN_LISTE: 'stammdaten:kundendaten-liste',
  STAMMDATEN__KUNDENDATEN_ALERTMAILS: 'stammdaten:kundendaten-alertmails',
  STAMMDATEN__KUNDENDATEN_ANLEGEN_MAINBUTTON: 'stammdaten:kundendaten-anlegen-mainbutton',
  STAMMDATEN__KUNDENDATEN_EDITIEREN_OPTION: 'stammdaten:kundendaten-editieren-option',
  STAMMDATEN__KUNDENDATEN_LOESCHEN_OPTION: 'stammdaten:kundendaten-loeschen-option',
  STAMMDATEN__KUNDENDATEN_ADMIN_LOESCHEN: 'stammdaten:kundendaten-admin-loeschen',
  STAMMDATEN__ANSPRECHPARTNER_LISTE: 'stammdaten:ansprechpartner-liste',
  STAMMDATEN__ANSPRECHPARTNER_ANLEGEN_MAINBUTTON: 'stammdaten:ansprechpartner-anlegen-mainbutton',
  STAMMDATEN__ANSPRECHPARTNER_EDITIEREN_OPTION: 'stammdaten:ansprechpartner-editieren-option',
  STAMMDATEN__ANSPRECHPARTNER_LOESCHEN_OPTION: 'stammdaten:ansprechpartner-loeschen-option',
  STAMMDATEN__INTERNMITARBEITER_LISTE: 'stammdaten:internmitarbeiter-liste',
  STAMMDATEN__INTERNMITARBEITER_ANLEGEN_MAINBUTTON:
    'stammdaten:internmitarbeiter-anlegen-mainbutton',
  STAMMDATEN__INTERNMITARBEITER_EDITIEREN_OPTION: 'stammdaten:internmitarbeiter-editieren-option',
  STAMMDATEN__INTERNMITARBEITER_LOESCHEN_OPTION: 'stammdaten:internmitarbeiter-loeschen-option',
  STAMMDATEN__INTERNMITARBEITER_ROLLENVERGABE_ADMIN:
    'stammdaten:internmitarbeiter-rollenvergabe-admin',
  STAMMDATEN__MEINE_PROJEKT_LISTE: 'stammdaten:meine-projekt-liste',
  STAMMDATEN__PROJEKT_LISTE: 'stammdaten:projekt-liste',
  STAMMDATEN__PROJEKT_ANLEGEN_MAINBUTTON: 'stammdaten:projekt-anlegen-mainbutton',
  STAMMDATEN__PROJEKT_ANZEIGEN_OPTION: 'stammdaten:projekt-anzeigen-option',
  STAMMDATEN__PROJEKT_EDITIEREN_OPTION: 'stammdaten:projekt-editieren-option',
  STAMMDATEN__PROJEKT_LOESCHEN_OPTION: 'stammdaten:projekt-loeschen-option',
  STAMMDATEN__PROJEKT_DATEIEN_ANZEIGEN: 'stammdaten:projekt-dateien-anzeigen',
  STAMMDATEN__PROJEKT_DATEIEN_HOCHLADEN: 'stammdaten:projekt-dateien-hochladen',
  STAMMDATEN__PROJEKT_DATEIEN_LOESCHEN: 'stammdaten:projekt-dateien-loeschen',
  AVAL__MEINE_LISTE: 'aval:meine-liste',
  AVAL__LISTE: 'aval:liste',
  AVAL__ANZEIGEN_OPTION: 'aval:anzeigen-option',
  AVAL__BEFRISTUNG_AENDERN_OPTION: 'aval:befristung-aendern-option',
  AVAL__BEFRISTUNG_TYP_AENDERN: 'aval:befristung-typ-aendern',
  AVAL__URKUNDE_ERSETZEN: 'aval:avalurkunde-ersetzen-option',
  AVAL__EDITIEREN_OPTION: 'aval:editieren-option',
  AVAL__NOTIZ_BEARBEITEN_OPTION: 'aval:notiz-bearbeiten-option',
  AVAL__AUSBUCHEN_OPTION: 'aval:ausbuchen-option',
  AVAL__REAKTIVIEREN_OPTION: 'aval:reaktivieren-option',
  AVAL__DATEIEN_ANZEIGEN_OPTION: 'aval:dateien-anzeigen-option',
  AVAL__ZU_STATUS_ANFRAGE: 'aval:zu-status-anfrage',
  AVAL__DATEIEN_LOESCHEN: 'aval:dateien-loeschen',
  AVAL__DATEIEN_HOCHLADEN: 'aval:dateien-hochladen',
  AVAL__ANLEGEN: 'aval:anlegen',
  AVAL__IMPORTIEREN: 'aval:importieren',
  INCOMING_AVAL__MEINE_LISTE: 'incoming-aval:meine-liste',
  INCOMING_AVAL__LISTE: 'incoming-aval:liste',
  INCOMING_AVAL__ANZEIGEN_OPTION: 'incoming-aval:anzeigen-option',
  INCOMING_AVAL__BEFRISTUNG_AENDERN_OPTION: 'incoming-aval:befristung-aendern-option',
  INCOMING_AVAL__EDITIEREN_OPTION: 'incoming-aval:editieren-option',
  INCOMING_AVAL__NOTIZ_BEARBEITEN_OPTION: 'incoming-aval:notiz-bearbeiten-option',
  INCOMING_AVAL__AUSBUCHEN_OPTION: 'incoming-aval:ausbuchen-option',
  INCOMING_AVAL__REAKTIVIEREN_OPTION: 'incoming-aval:reaktivieren-option',
  INCOMING_AVAL__ANLEGEN: 'incoming-aval:anlegen',
  INCOMING_AVAL__IMPORTIEREN: 'incoming-aval:importieren',
  INCOMING_AVAL__STAMMDATEN: 'incoming-aval:stammdaten',
  INCOMING_AVAL__MEINE_STAMMDATEN: 'incoming-aval:meine-stammdaten',
  INCOMING_AVAL__DELETE: 'incoming-aval:delete',
  AVALANFRAGEN__MEINE_LISTE: 'avalanfragen:meine-liste',
  AVALANFRAGEN__LISTE: 'avalanfragen:liste',
  AVALANFRAGEN__ERSTELLEN_STAFF: 'avalanfragen:erstellen-step1-staff',
  AVALANFRAGEN__ERSTELLEN: 'avalanfragen:erstellen-step1',
  AVALANFRAGEN__ERSTELLEN_STEP1_SONDERTEXTUPLOAD: 'avalanfragen:erstellen-step1-sondertextupload',
  AVALANFRAGEN__ERSTELLEN_STEP2_AVISIERT_SPEICHERN:
    'avalanfragen:erstellen-step2-avisiert-speichern',
  AVALANFRAGEN__ERSTELLEN_STEP2_KONZERN: 'avalanfragen:erstellen-step2-konzern',
  AVALANFRAGEN__ERSTELLEN_STEP2_FREIGEBEN: 'avalanfragen:erstellen-step2-freigeben',
  AVALANFRAGEN__ANZEIGEN_OPTION: 'avalanfragen:anzeigen-option',
  AVALANFRAGEN__NOTIZ_EDITIEREN_OPTION: 'avalanfragen:notiz-editieren-option',
  AVALANFRAGEN__LOESCHEN_OPTION: 'avalanfragen:loeschen-option',
  AVALANFRAGEN__GEPLANT_BEARBEITEN: 'avalanfragen:geplant-bearbeiten',
  AVALANFRAGEN__ENTWURF_LOESCHEN: 'avalanfragen:entwurf-loeschen',
  AVALANFRAGEN__GEPLANT_LOESCHEN: 'avalanfragen:geplant-loeschen',
  AVALANFRAGEN__AVALURKUNDE_EINGABE_OPTION: 'avalanfragen:avalurkunde-eingabe-option',
  AVALANFRAGEN__PDF_ERSTELLEN_OPTION: 'avalanfragen:pdf-erstellen-option',
  AVALANFRAGEN__WEITER_EDITIEREN_OPTION: 'avalanfragen:weiter-editieren-option',
  AVALANFRAGEN__STATUS_ENTWURF_SETZEN: 'avalanfragen:status-entwurf-setzen',
  DATEIEN__HERUNTERLADEN: 'dateien:herunterladen',
  KREDITZUSAGEN__MEINE_LISTE: 'kreditzusagen:meine-liste',
  KREDITZUSAGEN__LISTE: 'kreditzusagen:liste',
  KREDITZUSAGEN__ANZEIGEN_OPTION: 'kreditzusagen:anzeigen-option',
  KREDITZUSAGEN__EDITIEREN_OPTION: 'kreditzusagen:editieren-option',
  KREDITZUSAGEN__LOESCHEN_OPTION: 'kreditzusagen:loeschen-option',
  KREDITZUSAGEN__ANSPRECHPARTNER_INFO_OPTION: 'kreditzusagen:ansprechpartner-info-option',
  KREDITZUSAGEN__ANLEGEN: 'kreditzusagen:anlegen',
  REPORTS__MEINE_GESAMTOBLIGO_LISTE: 'reports:meine-gesamtobligo-liste',
  REPORTS__GESAMTOBLIGO_LISTE: 'reports:gesamtobligo-liste',
  REPORTS__MEINE_SUBLIMITS_LISTE: 'reports:meine-sublimits-liste',
  REPORTS__SUBLIMITS_LISTE: 'reports:sublimits-liste',
  REPORTS__AUFTRAGSWERT_LISTE: 'reports:auftragswert-liste',
  REPORTS__NUTZERSTATISTIK_LISTE: 'reports:nutzerstatistik-liste',
  SYSTEM__LOESCHEN: 'system:loeschen',
  SYSTEM__USERANLAGE: 'system:useranlage',
  SYSTEM__USERLOGIN_RESET: 'system:userlogin-reset',
  KONZERN_GENEHMIGER: 'konzern-genehmiger',
  STAFF: 'staff',
}