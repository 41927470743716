import { TFunction } from 'react-i18next'
import Select from '../models/select'
import { isString } from '../utils/general-util'
import { Subject } from 'rxjs'
import { Indexable } from '../../types'
import Option from '../models/option'
import { RETURN_TO } from '../features/buergschaft/incoming/returnToEnumHolder'

class TranslateService {
  private _t: TFunction<string> | undefined
  private _language: string | undefined
  private missingTranslations: Indexable = {}

  public language$: Subject<string> = new Subject<string | undefined>()

  constructor(t: TFunction<string> | undefined) {
    this._t = t
  }

  set t(value: TFunction<string>) {
    this._t = value
  }

  set language(value: string) {
    this._language = value
  }

  thousandSeparator: () => string = () => {
    // return this._language === 'en' ? ',' : '.'
    return '.'
  }

  decimalSeparator: () => string = () => {
    // return this._language === 'en' ? '.' : ','
    return ','
  }

  locale: () => string = () => {
    return this._language === 'en' ? 'en-GB' : 'de-DE'
  }

  calendarLocale: () => string = () => {
    return this._language === 'en' ? 'en' : 'de'
  }

  translate: (text: string) => string = (text) => {
    if (!text) {
      return text
    }
    if (!isString(text)) {
      console.warn('Just string values can be translated', text)
      return text
    }

    if (!this._t) {
      return text
    }

    if (this._language !== 'en') {
      return text
    }

    const possiblePostFixes = ['. ', '...', ': *', ' *', '* ', '*', ': ', ':', '.', ' %', '%', '?']

    let translation
    for (const postFix of possiblePostFixes) {
      if (text.endsWith(postFix)) {
        const _text = text.substring(0, text.lastIndexOf(postFix))
        if (
          this._t(_text) !== undefined &&
          (this.termsHavingSameTranslation.includes(_text) || this._t(_text) !== _text)
        ) {
          translation = this._t(_text) + postFix
          break
        }
      }
    }

    if (!translation) {
      translation = this._t(text)
    }

    if (
      this._language === 'en' &&
      translation === text &&
      !this.missingTranslations[translation] &&
      !this.termsHavingSameTranslation.includes(text)
    ) {
      this.missingTranslations[translation] = translation
      // console.log('Translation missing for ' + translation)
    }
    return translation
  }

  translateSelect: (options: Select[] | string[]) => string = (options) => {
    if (options && options.length > 0) {
      if (isString(options[0])) {
        options = options.map((option) => this.translate(option))
      } else {
        options.forEach((option) => {
          option.label = this.translate(option.label)
        })
      }
    }
    return options
  }

  translateOption: (options: Option[] | string[]) => Option[] | string[] = (options) => {
    if (options && options.length > 0) {
      if (isString(options[0])) {
        options = options.map((option) => this.translate(option))
      } else {
        options.forEach((option: Option) => {
          option.name = this.translate(option.name)
        })
      }
    }
    return options
  }

  translateByField: (options: Indexable[], fieldName: string) => string = (options, fieldName) => {
    if (options && options.length > 0) {
      options = options.map((option) => {
        if (option && option[fieldName]) {
          option[fieldName] = this.translate(option[fieldName])
        }
        return option
      })
    }
    return options
  }

  printMissingTranslations: () => string = () => {
    console.log(this.missingTranslations)
  }

  translateReturnTo = (str: string) => {
    if(!str) {
      return ""
    }
    return RETURN_TO[str]
  }

  private termsHavingSameTranslation: string[] = [
    'Portugal',
    'Afghanistan',
    'Andorra',
    'Angola',
    'Bahamas',
    'Bahrain',
    'Barbados',
    'Belize',
    'Benin',
    'Bhutan',
    'Botswana',
    'Brunei',
    'Burkina Faso',
    'Chile',
    'Costa Rica',
    'Dominica',
    'Ecuador',
    'Eritrea',
    'Gambia',
    'Ghana',
    'Guatemala',
    'Guinea',
    'Guyana',
    'Haiti',
    'Honduras',
    'Iran',
    'Israel',
    'Japan',
    'Kiribati',
    'Kuwait',
    'Laos',
    'Lesotho',
    'Liberia',
    'Liechtenstein',
    'Malawi',
    'Malaysia',
    'Malta',
    'Mauritius',
    'Monaco',
    'Montenegro',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Oman',
    'Pakistan',
    'Palau',
    'Panama',
    'Paraguay',
    'Peru',
    'Samoa',
    'San Marino',
    'Senegal',
    'Sierra Leone',
    'Somalia',
    'Sri Lanka',
    'Sudan',
    'Suriname',
    'Taiwan',
    'Thailand',
    'Togo',
    'Tonga',
    'Turkmenistan',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'Uruguay',
    'Vanuatu',
    'Venezuela',
    'Vietnam',
    'Newsletter',
    'Status',
    'Bond ID',
    'Date of Application',
    'Company',
    'Guarantor',
    'Remarks',
    'Bond Facility Number',
    'Security Purpose',
    'Order Number',
    'Type of Bond',
    'Project Number',
    'Project Name',
    'Amount',
    'Beneficiary',
    'Bond Period Until',
    'Time Limit',
    'CONSORTIUM',
    'Bond Text',
    'System Information',
    'Filter...',
    'Sublimit',
    'Sublimit Report',
    'User',
    'Login',
    'MaBV',
    'Standby Letters of Credit',
    'Fronting',
    'No Surcharge',
    'Surcharge Plus Costs',
    'Sucharge Plus Interests',
    'Surcharge Plus Interests and Costs',
    'Surcharge Employee Posting Act',
    'Surcharge Social Code',
    'Surcharge Employee Posting Act and Social Code',
    'Limits',
    'Surety Type',
    'Vadium',
    'Website',
    'Supported in Schumann API',
    'Email',
    'Type',
    'Co-Insured Company',
    'Partner Name',
    'Bond Number',
    'Date of Issue',
    'Write-Off Date',
    'Company Order Number',
    'Beginning of the Bond',
    'Indefinite Period',
    'Contract Period',
    'Text Language',
    'Beneficiary Country of the Bond',
    'Place of Jurisdiction',
    'Construction Project / Plant Project',
    'Display row errors on top',
    'Home',
    'Reports',
    'E-Mail',
    'Admin',
    'BRD',
    'EFTA',
    'Email',
    'EU',
    'Fax',
    'Name',
    'Sublimit',
    'Sublimit in',
    'Vadium',
  ]
}

export default new TranslateService(undefined)
