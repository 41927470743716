import React, { FC } from 'react'
import './XLabelValue.scss'
import { Link } from 'react-router-dom'
import { htmlToString } from '../utils/general-util'
import ReactDOMServer from 'react-dom/server'
import TranslateService from '../services/translate-service'

type LabelValueProps = {
  label: string
  value: any
  linkDestination?: string
  filterText?: string
}

const XLabelValue: FC<LabelValueProps> = ({ label, value, linkDestination, filterText }) => {
  if (!value) {
    value = '-'
  }
  let hide = false
  if (filterText) {
    let textValue = value
    if (React.isValidElement(textValue)) {
      textValue = htmlToString(ReactDOMServer.renderToString(textValue))
    }
    hide =
      (!label || label.toLowerCase().indexOf(filterText.toLowerCase()) === -1) &&
      (!textValue || String(textValue).toLowerCase().indexOf(filterText.toLowerCase()) === -1)
  }

  if (hide) {
    return <></>
  }

  return (
    <div className="wrapper" key={new Date().valueOf()}>
      <span className="label">{TranslateService.translate(label)}:</span>
      {linkDestination && (
        <Link key={linkDestination} to={linkDestination} target="_blank" rel="noopener noreferrer">
          {value}
        </Link>
      )}
      {!linkDestination && <span className="value">{value}</span>}
    </div>
  )
}

export default XLabelValue
