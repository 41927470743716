import Axios from 'axios-observable'
import Select from '../../models/Select'
import Option from '../../models/option'
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'
import Firma from '../../models/firma'
import FirmaSlim from '../../models/firma-slim'
import FirmaKonzern from '../../models/firma-konzern'
import { BehaviorSubject } from 'rxjs'

class KundeService {

  public selectedKundenIds$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([])
  public selectedKundenIds :number[] = []

  setCurrentKonzerne = (kundenIds: number[]) => {
    this.selectedKundenIds$.next(kundenIds)
    if (kundenIds) {
      this.selectedKundenIds = kundenIds
    }
  }

  getList: () => AxiosObservable<Firma[]> = () => {
    return Axios.get('firma')
  }

  getListSlim: () => AxiosObservable<FirmaSlim[]> = () => {
    return Axios.get('firma/slim?sorted=true')
  }

  load(id: number) {
    return Axios.get(`firma/${id}`)
  }

  save(Firma: Firma) {
    if (Firma.id) {
      return Axios.put('firma', Firma)
    } else {
      return Axios.post('firma', Firma)
    }
  }

  delete(id: number) {
    return Axios.delete(`firma/${id}`)
  }

  sendWeeklyEmails() {
    return Axios.post('alerts/woechentlich')
  }

  sendMonthlyEmails() {
    return Axios.post('alerts/monatlich')
  }

  sendIncomingAvaleEmails() {
    return Axios.post('alerts/incoming-aval')
  }

  getEmpty: () => Firma = () => {
    return {
      id: undefined,
      ansprechpartnerList: [],
      ansprechpartnerBerechtigungList: [],
      emails: [],
      emailsCC: [],
      branche: undefined,
      kundennummer: '',
      handeslregisternummer: '',
      emailempfang: '',
      doesPersist: true,
      // last day of the year
      bilanzstichtag: new Date(new Date().getFullYear(), 12, 0),
      firmaDetail: {
        anschrift: {
          land: 'DEU',
        },
      },
      active: true,
      needsFreigabeworkflow: false,
    }
  }

  getForSelect(list: Option[]): Select[] {
    if (!list) {
      return []
    }
    return list.map((item) => {
      return {
        label: item.name,
        value: item.id,
      }
    })
  }

  getForSelectInArray(list: Option[]): Select[] {
    if (!list) {
      return []
    }
    return list.map((item) => {
      return {
        label: item.name,
        value: item.name,
      }
    })
  }

  getAlertempfangForSelect(): Select[] {
    return [
      {
        label: 'Monatlich',
        value: 'MONATLICH',
      },
      {
        label: 'Wöchentlich',
        value: 'WOECHENTLICH',
      },
    ]
  }

  fetchBrancheList() {
    return Axios.get('branche')
  }

  fetchAllAnsprechpartner() {
    return Axios.get('firma/ansprechpartner')
  }

  fetchAllGenehmiger() {
    return Axios.get('mitarbeiter/genehmiger')
  }

  fetchAllUser() {
    return Axios.get('user')
  }

  fetchBulkDeleteInfo(id: number) {
    return Axios.get(`firma/${id}/bulk-delete`)
  }
  bulkDelete(id: number) {
    return Axios.delete(`firma/${id}/bulk-delete`)
  }

  getFirmaKonzernList: () => AxiosObservable<FirmaKonzern[]> = () => {
    return Axios.get('firma/firma-konzern')
  }

  getKonzernList: () => AxiosObservable<FirmaSlim[]> = () => {
    return Axios.get('firma/konzerne')
  }

}

export default new KundeService()
