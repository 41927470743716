import { version } from '../info/info.json'
import { versionToNumber } from '../utils/general-util'

class LocalStorageService {
  ls = window.localStorage

  /**
   * This object keeps a list of all the caches currently managed by the LocalStorageService
   * The version next to each cache key shows at which version in was introduced/modified
   * Note: after resetting a cache, make sure that the respective value is this object is set
   *       to the respective version, otherwise it would be reset at each deployment
   */
  cacheResets: object = {
    'ansprechpartner-table-visible': '0.0.1',
    'auftragswert-column-orders': '0.0.0',
    'auftragswert-selected-fields': '0.0.0',
    auth_user: '0.0.0',
    stored_path: '0.0.0',
    jwt_token: '0.0.0',
    user_roles: '0.0.0',
    'data-to-import': '2.11.13',
    'selected-aval-statuses': '2.11.18',
    'buergschaft-column-orders': '2.10.18',
    'buergschaft-selected-fields': '2.10.18',
    'buergschaftsanfrage-ergebnis-column-orders': '2.10.11',
    'buergschaftsanfrage-ergebnis-table-visible': '2.10.11',
    'buergschaftsanfrage-column-orders': '2.16.30',
    'buergschaftsanfrage-selected-fields': '2.16.30',
    'gesamtobligo-column-orders': '2.10.10',
    'gesamtobligo-selected-fields': '2.10.10',
    'incoming-aval-column-orders': '2.14.3',
    'incoming-aval-selected-fields': '2.14.3',
    'kreditzusage-column-orders': '2.10.10',
    'kreditzusage-selected-fields': '2.10.10',
    'kunde-table-visible': '2.14.1',
    'mitarbeiter-table-visible': '0.0.0',
    'obligo-nach-sublimit-table-visible': '2.10.10',
    'praemien-table-visible': '0.0.0',
    'versicherer-table-visible': '0.0.0',
    'projekt-selected-fields': '2.8.15',
    'projekt-column-orders': '2.8.15',
    'selected-aval-anfrage-statuses': '2.3.29',
    'pagination-info': '0.0.0',
    'incoming-aval-to-import': '2.14.14',
    current_konzern: '0.0.0',
  }

  keyExists: (key: string) => boolean = (key) => {
    const match = Object.keys(this.cacheResets).find((_key) => key === _key)
    return match !== undefined
  }

  getPreviouslyResetCaches: () => object = () => {
    let previouslyResetCaches: object = this.getItem('cache-resets')
    if (!previouslyResetCaches) {
      previouslyResetCaches = {}
      Object.entries(this.cacheResets).forEach(([key, _]) => {
        previouslyResetCaches[key] = '0.0.0'
      })
      this.setItem('cache-resets', previouslyResetCaches)
    }
    return previouslyResetCaches
  }

  resetCachesIfNeeded: () => void = () => {
    const previouslyResetCaches: object = this.getPreviouslyResetCaches()
    Object.entries(this.cacheResets).forEach(([cacheName, resetVersion]) => {
      const previouslyResetVersion = previouslyResetCaches[cacheName]
      // if the cache for this cacheName was never reset or if it was reset before the deployment time, reset it
      if (
        !previouslyResetVersion ||
        versionToNumber(resetVersion) > versionToNumber(previouslyResetVersion)
      ) {
        // console.log(
        //   `Resetting the cache ${cacheName} at version ${version}, previous reset version: ${previouslyResetVersion}`
        // )
        this.removeItem(cacheName)
        previouslyResetCaches[cacheName] = version
        this.setItem('cache-resets', previouslyResetCaches)
      }
    })
  }

  resetAllManagedCaches: () => void = () => {
    const previouslyResetCaches: object = this.getPreviouslyResetCaches()
    Object.entries(this.cacheResets).forEach(([cacheName, resetVersion]) => {
      const previouslyResetVersion = previouslyResetCaches[cacheName]
      const cachesToIgnore = ['auth_user', 'jwt_token', 'user_roles']
      // if the cache for this cacheName was never reset or if it was reset before the deployment time, reset it
      if (!cachesToIgnore.includes(cacheName)) {
        // console.log(
        //   `Resetting the cache ${cacheName} at version ${version}, previous reset version: ${previouslyResetVersion}`
        // )
        this.removeItem(cacheName)
        previouslyResetCaches[cacheName] = version
        this.setItem('cache-resets', previouslyResetCaches)
      }
    })
  }

  setItem(key: string, value: any) {
    this.validateKey(key)
    value = JSON.stringify(value)
    this.ls.setItem(key, value)
    return true
  }

  getItem: (key: string, defaultValue?: any) => any = (key, defaultValue) => {
    this.validateKey(key)
    const value = this.ls.getItem(key)
    if (!value) {
      return defaultValue
    }
    try {
      return JSON.parse(value)
    } catch (e) {
      return null
    }
  }

  removeItem: (key: string) => any = (key) => {
    this.validateKey(key)
    try {
      this.ls.removeItem(key)
    } catch (e) {
      console.warn(`There was a problem releasing the cache with the key ${key}`)
    }
  }

  validateKey: (key: string) => void = (key) => {
    if (key !== 'cache-resets' && !this.keyExists(key)) {
      throw new Error(`The key ${key} is not managed yet by the local-storage-service.`)
    }
  }
}

export default new LocalStorageService()
