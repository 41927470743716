import React, { Component, Suspense } from 'react'
import { renderRoutes } from 'react-router-config'

import Footer from './Footer'
import Header from './Header'
import Sidenav from './Sidenav'

import { connect } from 'react-redux'

import { classList } from 'app/utils/@utils'
import { merge } from 'lodash'
import { setLayoutSettings } from 'app/redux/actions/LayoutActions'
import Loading from './components/GullLoadable/Loading'

class Layout2 extends Component {
  state = {}

  handleSearchBoxClose = () => {
    const { setLayoutSettings, settings } = this.props
    setLayoutSettings(
      merge({}, settings, {
        layoutSettings: {
          searchBox: {
            open: false,
          },
        },
      })
    )
  }

  render() {
    const { settings, routes } = this.props

    // RGB: 236	168	59

    return (
      <div>
        <div
          className={`${classList({
            'app-admin-wrap layout-sidebar-compact clearfix': true,
            'sidenav-open': settings.layoutSettings.leftSidebar.open,
          })} ${settings.layoutSettings.leftSidebar.theme}`}
        >
          <Sidenav />
          {/* sidebar */}

          <div
            className={classList({
              'main-content-wrap d-flex flex-column': true,
              'sidenav-open': settings.layoutSettings.leftSidebar.open,
            })}
          >
            {settings.layoutSettings.header.show && <Header />}
            <Suspense fallback={<Loading />}>
              <div className="main-content">{renderRoutes(routes)}</div>
            </Suspense>
            <Footer />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  settings: state.layout.settings,
})

export default connect(mapStateToProps, { setLayoutSettings })(Layout2)
