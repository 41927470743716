import {lazy} from 'react'
import {GRoute} from '../../../models/Route'
import {AUTHORITIES} from "../../../auth/authorities";

const GerichtsstandEdit = lazy(() => import('./gerichtsstand-edit'))
const GerichtsstandList = lazy(() => import('./gerichtsstand-list'))

const gerichtsstandRoutes: GRoute[] = [
  {
    path: '/stammdaten/gerichtsstand/erstellen',
    component: GerichtsstandEdit,
    auth: AUTHORITIES.STAMMDATEN__GERICHTSSTAENDE_ANLEGEN_MAINBUTTON,
  },
  {
    path: '/stammdaten/gerichtsstand/editieren/:id',
    component: GerichtsstandEdit,
    auth: AUTHORITIES.STAMMDATEN__GERICHTSSTAENDE_EDITIEREN_OPTION,
  },
  {
    path: '/stammdaten/gerichtsstand/auflisten',
    component: GerichtsstandList,
    auth: AUTHORITIES.STAMMDATEN__GERICHTSSTAENDE_LISTE,
  },
]

export default gerichtsstandRoutes
