import { lazy } from 'react'
import { GRoute } from '../../../models/Route'
import { AUTHORITIES } from '../../../auth/authorities'

const BranchenEdit = lazy(() => import('./branche-edit'))
const BranchenList = lazy(() => import('./branche-list'))

const brancheRoutes: GRoute[] = [
  {
    path: '/stammdaten/branche/erstellen',
    component: BranchenEdit,
    auth: AUTHORITIES.STAMMDATEN__BRANCHEN_ANLEGEN_MAINBUTTON,
  },
  {
    path: '/stammdaten/branche/editieren/:id',
    component: BranchenEdit,
    auth: AUTHORITIES.STAMMDATEN__BRANCHEN_EDITIEREN_OPTION,
  },
  {
    path: '/stammdaten/branche/auflisten',
    component: BranchenList,
    auth: AUTHORITIES.STAMMDATEN__BRANCHEN_LISTE,
  },
]

export default brancheRoutes
