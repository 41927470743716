import React, { createContext, useContext, useRef } from 'react'
import parse from 'html-react-parser'
import TranslateService from '../services/translate-service'

export enum Severity {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export type ToastContextProps = {
  toast: any
  showSuccess: (summary: string, message: string, duration?: number) => void
  showWarning: (summary: string, message: string, duration?: number) => void
  showInfo: (summary: string, message: string, duration?: number) => void
  showError: (summary: string, message: string, duration?: number) => void
  showSticky: (severity: Severity, summary: string, message: string) => void
}

const ToastContext = createContext<ToastContextProps>(undefined)

export const useToast: () => ToastContextProps = () => {
  const context = useContext(ToastContext)
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}

export const ToastProvider = ({ children }) => {
  const toast = useRef(null)

  const showSticky = (severity: Severity, summary: string, message: string) => {
    toast.current!.show({
      severity,
      summary: TranslateService.translate(summary),
      detail: TranslateService.translate(message),
      sticky: true,
    })
  }

  const showError = (summary: string, message: string, duration = 10000) => {
    toast.current!.show({
      severity: Severity.error,
      summary: TranslateService.translate(summary),
      detail: parse(TranslateService.translate(message)),
      life: duration,
    })
  }

  const showInfo = (summary: string, message: string, duration = 4000) => {
    toast.current!.show({
      severity: Severity.info,
      summary: TranslateService.translate(summary),
      detail: TranslateService.translate(message),
      life: duration,
    })
  }

  const showWarning = (summary: string, message: string, duration = 4000) => {
    toast.current!.show({
      severity: Severity.warning,
      summary: TranslateService.translate(summary),
      detail: TranslateService.translate(message),
      life: duration,
    })
  }

  const showSuccess = (summary: string, message: string, duration = 4000) => {
    toast.current!.show({
      severity: Severity.success,
      summary: TranslateService.translate(summary),
      detail: TranslateService.translate(message),
      life: duration,
    })
  }

  const toastContext = { toast, showSuccess, showWarning, showInfo, showError, showSticky }

  return <ToastContext.Provider value={toastContext}>{children}</ToastContext.Provider>
}
