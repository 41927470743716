import { lazy } from 'react'
import { GRoute } from '../../models/Route'
import { AUTHORITIES } from '../../auth/authorities'

const ObligoEdit = lazy(() => import('./buergschaft-edit'))
const ObligoList = lazy(() => import('./buergschaft-list'))
const ObligoImport = lazy(() => import('./buergschaft-import'))

const buergschaftRoutes: GRoute[] = [
  {
    path: '/obligo/erstellen',
    component: ObligoEdit,
    auth: AUTHORITIES.AVAL__ANLEGEN,
  },
  {
    path: '/obligo/edit/:id',
    component: ObligoEdit,
    auth: AUTHORITIES.AVAL__EDITIEREN_OPTION,
  },
  {
    path: '/obligo/importieren',
    component: ObligoImport,
    auth: AUTHORITIES.AVAL__IMPORTIEREN,
  },
  {
    path: '/obligo/auflisten',
    component: ObligoList,
    auth: AUTHORITIES.AVAL__MEINE_LISTE,
  },
]

export default buergschaftRoutes
