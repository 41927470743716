import ansprechpartnerRoutes from './ansprechpartner/ansprechpartner-routes'
import brancheRoutes from './branche/branche-routes'
import buergschaftsartRoutes from './buergschaftsart/buergschaftsart-routes'
import gerichtsstandRoutes from './gerichtsstand/gerichtsstand-routes'
import versichererRoutes from './versicherer/versicherer-routes'
import mitarbeiterRoutes from './mitarbeiter/mitarbeiter-routes'
import { GRoute } from '../../models/Route'

const stammdatenRoutes: GRoute[] = [
  ...ansprechpartnerRoutes,
  ...brancheRoutes,
  ...buergschaftsartRoutes,
  ...gerichtsstandRoutes,
  ...versichererRoutes,
  ...mitarbeiterRoutes,
]

export default stammdatenRoutes
