import { lazy } from 'react'
import { GRoute } from '../../../models/Route'
import { AUTHORITIES } from '../../../auth/authorities'

const BuergschaftsartEdit = lazy(() => import('./buergschaftsart-edit'))
const BuergschaftsartList = lazy(() => import('./buergschaftsart-list'))

const buergschaftsartRoutes: GRoute[] = [
  {
    path: '/stammdaten/buergschaftsart/erstellen',
    component: BuergschaftsartEdit,
    auth: AUTHORITIES.STAMMDATEN__AVALARTEN_ANLEGEN_MAINBUTTON,
  },
  {
    path: '/stammdaten/buergschaftsart/editieren/:id',
    component: BuergschaftsartEdit,
    auth: AUTHORITIES.STAMMDATEN__AVALARTEN_EDITIEREN_OPTION,
  },
  {
    path: '/stammdaten/buergschaftsart/auflisten',
    component: BuergschaftsartList,
    auth: AUTHORITIES.STAMMDATEN__AVALARTEN_LISTE,
  },
]

export default buergschaftsartRoutes
