import {lazy} from 'react'
import {GRoute} from '../../../models/Route'
import {AUTHORITIES} from "../../../auth/authorities";

const AnsprechpartnerEdit = lazy(() => import('./ansprechpartner-edit'))
const AnsprechpartnerList = lazy(() => import('./ansprechpartner-list'))

const ansprechpartnerRoutes: GRoute[] = [
  {
    path: '/stammdaten/ansprechpartner/erstellen',
    component: AnsprechpartnerEdit,
    auth: AUTHORITIES.STAMMDATEN__ANSPRECHPARTNER_ANLEGEN_MAINBUTTON,
  },
  {
    path: '/stammdaten/ansprechpartner/editieren/:id',
    component: AnsprechpartnerEdit,
    auth: AUTHORITIES.STAMMDATEN__ANSPRECHPARTNER_EDITIEREN_OPTION,
  },
  {
    path: '/stammdaten/ansprechpartner/auflisten',
    component: AnsprechpartnerList,
    auth: AUTHORITIES.STAMMDATEN__ANSPRECHPARTNER_LISTE,
  },
]

export default ansprechpartnerRoutes
