import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react'
import LocalStorageService from './services/localStorageService'
import WindowService from './services/window-service'
import { i18Init } from './i18n'
import 'primeflex/primeflex.css'
import 'primereact/resources/themes/mdc-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import '../styles/app/app.scss'

import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import AppContext from './appContext'
import history from 'app/utils/@history'
import RequestService from './services/request-service'

import routes from './root-routes'
import { Store } from './redux/Store'
import { renderRoutes } from 'react-router-config'
import Loading from './Layout/components/GullLoadable/Loading'
import { ToastProvider } from './toast/toast-context'
import XToast from './toast/XToast'
import { Auth0Provider } from '@auth0/auth0-react'
import { addLocales } from './utils/date-util'
import { ErrorBoundary } from 'react-error-boundary'
import { Messages } from 'primereact/messages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBug } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import InfoView from './info/info-view'
import { Subscription } from 'rxjs'
import InfoService from './info/info-service'
import ConfigService from './config/config-service'
import ConfigView from './config/config-view'
import TranslateService from './services/translate-service'

function ErrorFallback({ error, resetErrorBoundary }) {
  const errorMessage = useRef(null)

  useEffect(() => {
    errorMessage.current.show({
      severity: 'error',
      sticky: true,
      content: (
        <React.Fragment>
          <div className="p-ml-2">
            <FontAwesomeIcon icon={faBug} className="p-mr-2" />
            {'Diese Seite kann aus einem technischen Grund nicht angezeigt werden.'}
            <br />
            {
              'Um den Cache zu leeren und die Seite neu zu laden, klicken Sie auf die Schaltfläche unten.'
            }
            <br />
            {
              'Sollte das Problem weiterhin bestehen, wenden Sie sich an den Systemadministrator.'
            }{' '}
            <br />
            <Button
              icon="pi pi-times-circle"
              tooltip={'Caches ausleeren und erneut versuchen'}
              onClick={resetAllCachesAndReload}
              className="p-button-danger w-25"
            >
              {'Caches ausleeren und erneut versuchen'}
            </Button>
          </div>
        </React.Fragment>
      ),
    })
  }, [])

  const resetAllCachesAndReload = useCallback(() => {
    console.info('Inside resetAllCachesAndReload...')
    LocalStorageService.resetAllManagedCaches()
    window.location.href = window.location.href
  }, [])

  return (
    <>
      <Messages ref={errorMessage} />
    </>
  )
}

function App() {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [loading, setLoading] = useState(true)
  const [displayInfo, setDisplayInfo] = useState<boolean>(false)
  const [displayConfig, setDisplayConfig] = useState<boolean>(false)

  i18Init()

  useEffect(() => {
    RequestService.init()

    addLocales()

    WindowService.setHeight(window.innerHeight)
    window.addEventListener('resize', () => WindowService.setHeight(window.innerHeight))

    addSub(
      RequestService.loading$().subscribe((_loading: boolean) =>
        setTimeout(() => setLoading(_loading), 0)
      )
    )

    // clean up the subscriptions on-destroy
    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe())
    }
  }, [])

  const addSub = (sub: Subscription) => {
    setSubscriptions([sub, ...subscriptions])
  }

  /**
   * Info Dialog configuration
   */
  useEffect(() => {
    addSub(
      InfoService.displayInfoDialog$.subscribe((_displayInfo: boolean) => {
        setTimeout(() => setDisplayInfo(_displayInfo), 0)
      })
    )
    addSub(
      ConfigService.displayConfigDialog$.subscribe((_displayConfig: boolean) => {
        setTimeout(() => setDisplayConfig(_displayConfig), 0)
      })
    )
    // clean up the subscriptions on-destroy
    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe())
    }
  }, [])

  let auth0Domain: string = `${process.env.REACT_APP_AUTH0_DOMAIN}`
  let auth0ClientId: string = `${process.env.REACT_APP_AUTH0_CLIENT_ID}`
  let auth0Audience: string = `${process.env.REACT_APP_AUTH0_AUDIENCE}`
  let auth0Scope: string = `${process.env.REACT_APP_AUTH0_SCOPE}`
  if (window.ENV) {
    console.log('Setting window environment variables...')
    auth0Domain = `${window.ENV.REACT_APP_AUTH0_DOMAIN}`
    auth0ClientId = `${window.ENV.REACT_APP_AUTH0_CLIENT_ID}`
    auth0Audience = `${window.ENV.REACT_APP_AUTH0_AUDIENCE}`
    auth0Scope = `${window.ENV.REACT_APP_AUTH0_SCOPE}`
  }
  console.log('Connecting to Auth0 domain: ', auth0Domain)

  // resetting the necessary caches
  LocalStorageService.resetCachesIfNeeded()

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        redirectUri={window.location.origin}
        audience={auth0Audience}
        scope={auth0Scope}
      >
        <ToastProvider>
          <AppContext.Provider value={{ routes }}>
            <Provider store={Store}>
              <Suspense fallback={<Loading />}>
                {loading && <Loading />}
                <XToast />
                <Dialog
                  header={TranslateService.translate('System Information')}
                  visible={displayInfo}
                  modal={true}
                  style={{ width: '750px', height: '50vh' }}
                  onHide={() => InfoService.hideInfoDialog()}
                >
                  <InfoView />
                </Dialog>
                <Dialog
                  header={TranslateService.translate('Konfiguration')}
                  visible={displayConfig}
                  modal={true}
                  style={{ width: '750px', height: '30vh' }}
                  onHide={() => ConfigService.hideConfigDialog()}
                >
                  <ConfigView />
                </Dialog>

                <Router history={history}>{renderRoutes(routes)}</Router>
              </Suspense>
            </Provider>
          </AppContext.Provider>
        </ToastProvider>
      </Auth0Provider>
    </ErrorBoundary>
  )
}

export default App
