import { lazy } from 'react'
import { AUTHORITIES } from '../../auth/authorities'

const BuergschaftsanfrageCreate = lazy(() => import('./buergschaftsanfrage-create'))
const BuergschaftsanfrageList = lazy(() => import('./buergschaftsanfrage-list'))

const buergschaftsanfrageRoutes = [
  {
    path: '/buergschaftsanfrage/erstellen',
    component: BuergschaftsanfrageCreate,
    auth: AUTHORITIES.AVALANFRAGEN__ERSTELLEN,
  },
  {
    path: '/buergschaftsanfrage/bearbeiten/:id',
    component: BuergschaftsanfrageCreate,
    auth: AUTHORITIES.AVALANFRAGEN__WEITER_EDITIEREN_OPTION,
  },
  {
    path: '/buergschaftsanfrage/auflisten',
    component: BuergschaftsanfrageList,
    auth: AUTHORITIES.AVALANFRAGEN__MEINE_LISTE,
  },
]

export default buergschaftsanfrageRoutes
