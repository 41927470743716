import React from 'react'
import { Link } from 'react-router-dom'
import { classList } from 'app/utils/@utils'
import DropDownMenuItem from './DropDownMenuItem'
import TranslateService from '../../services/translate-service'

const DropDownMenu = ({ menu, closeSecSidenav }) => {

  const renderLevels = (items) =>
    items.map((item, i) => {
      if (item.sub) {
        return (
          <DropDownMenuItem key={i} item={item}>
            {renderLevels(item.sub)}
          </DropDownMenuItem>
        )
      } else {
        return (
          <li
            key={i}
            className={classList({
              'nav-item': true,
              open: false,
            })}
            onClick={closeSecSidenav}
          >
            <Link to={item.path}>
              <i className={`im nav-icon ${item.icon}`} />
              <span className="item-name">{TranslateService.translate(item.name)}</span>
            </Link>
          </li>
        )
      }
    })

  return <ul className="childNav">{renderLevels(menu)}</ul>
}

export default DropDownMenu
