import { SET_LAYOUT_SETTINGS } from '../actions/LayoutActions'
import layoutSettings from '../../Layout/layoutSettings'

const initialState = {
  settings: {
    layoutSettings: { ...layoutSettings },
  },
}

const LayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAYOUT_SETTINGS:
      return {
        ...state,
        settings: { ...action.data },
      }
    default:
      return { ...state }
  }
}

export default LayoutReducer
