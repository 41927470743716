import React, { useEffect, useState } from 'react'
import LandingPage from 'app/Layout/LandingPage'
import AuthService from '../services/auth-service'
import Axios from 'axios-observable'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import { Subscription } from 'rxjs'
import Loading from '../Layout/components/GullLoadable/Loading'
import localStorageService from '../services/localStorageService'
import { useTranslation } from 'react-i18next'
import TranslateService from '../services/translate-service'

const AuthGuard = (props: any) => {
  const { route } = props
  const { isLoading, isAuthenticated, loginWithRedirect, user, getAccessTokenSilently } = useAuth0()
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [loadingRoles, setLoadingRoles] = useState<boolean>(true)
  const [language, setLanguage] = useState<string | undefined>()
  const { t, i18n } = useTranslation()

  TranslateService.t = t
  TranslateService.language = i18n.language
  if (i18n.language) {
    setTimeout(() => setLanguage(i18n.language), 0)
  }

  const addSub = (sub: Subscription) => {
    setSubscriptions([sub, ...subscriptions])
  }

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!isAuthenticated) {
      loginWithRedirect({
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      })
    }

    getAccessTokenSilently().then((token) => localStorageService.setItem('jwt_token', token))

    const requestInterceptorId = Axios.interceptors.request.use(async (config) => {
      const token = await getAccessTokenSilently()
      config.headers.authorization = `Bearer ${token}`
      config.cancelToken = axios.CancelToken.source().token
      AuthService.setUser(user)
      return config
    })

    const responseInterceptorId = Axios.interceptors.response.use(null, async (error) => {
      if (error.config && error.response && error.response.status === 401) {
        await loginWithRedirect({
          redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
        })
      }
      return Promise.reject(error)
    })

    addSub(
      TranslateService.language$.subscribe((_language: string) => {
        if (_language) {
          TranslateService.language = _language
          setTimeout(() => setLanguage(_language), 0)
        }
      })
    )

    addSub(
      AuthService.retrieveRoles().subscribe((response: any) => {
        localStorageService.setItem('user_roles', response.data)
        setLoadingRoles(false)
      })
    )

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe())
      Axios.interceptors.request.eject(requestInterceptorId)
      Axios.interceptors.response.eject(responseInterceptorId)
    }
  }, [isLoading, isAuthenticated, language])

  if (isLoading || loadingRoles) {
    return <Loading />
  } else {
    return <LandingPage route={route} />
  }
}

export default AuthGuard
