import { lazy } from 'react'
import { AUTHORITIES } from '../../auth/authorities'

const ProjektEdit = lazy(() => import('./projekt-edit'))
const ProjektList = lazy(() => import('./projekt-list'))

const projektRoutes = [
  {
    path: '/projekt/erstellen',
    component: ProjektEdit,
    auth: AUTHORITIES.STAMMDATEN__PROJEKT_ANLEGEN_MAINBUTTON,
  },
  {
    path: '/projekt/edit/:id',
    component: ProjektEdit,
    auth: AUTHORITIES.STAMMDATEN__PROJEKT_EDITIEREN_OPTION,
  },
  {
    path: '/projekt/auflisten',
    component: ProjektList,
    auth: AUTHORITIES.STAMMDATEN__MEINE_PROJEKT_LISTE,
  },
]

export default projektRoutes
