import React from 'react'

const styles = {
  position: 'fixed',
  left: 0,
  right: 0,
  top: 'calc(50% - 30px)',
  margin: 'auto',
  height: '60px',
  width: '60px',
  zIndex: 2147483647,
}

const Loading = () => {
  return (
    <div style={styles}>
      <div className="spinner spinner-bubble spinner-bubble-gracher-dark" />
    </div>
  )
}

export default Loading
