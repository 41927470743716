import { BehaviorSubject } from 'rxjs'

class ConfigService {
  public displayConfigDialog$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  displayConfigDialog = () => {
    this.displayConfigDialog$.next(true)
  }

  hideConfigDialog = () => {
    this.displayConfigDialog$.next(false)
  }
}

export default new ConfigService()
