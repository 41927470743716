import Axios from 'axios-observable'
import { BehaviorSubject } from 'rxjs'
import { RedirectLoginOptions } from '@auth0/auth0-react/src/auth0-context'
import TranslateService from './translate-service'

class RequestService {
  static loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  static showError: (summary: string, message: string, duration?: number) => void
  static loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>
  private static counter: number = 0

  public loading$() {
    return RequestService.loading$
  }

  public setToastMethod(
    showError: (summary: string, message: string, duration?: number) => void,
    loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>
  ) {
    RequestService.showError = showError
    RequestService.loginWithRedirect = loginWithRedirect
  }

  init = () => {
    let REACT_APP_API_URL: string
    if (window.ENV) {
      REACT_APP_API_URL = `${window.ENV.REACT_APP_API_URL}`
    } else {
      REACT_APP_API_URL = `${process.env.REACT_APP_API_URL}`
    }

    Axios.defaults.baseURL = REACT_APP_API_URL
    console.log(`Connecting with server ${REACT_APP_API_URL}`)

    Axios.interceptors.request.use(
      (config) => {
        config.headers['Accept-Language'] = TranslateService.locale()
        RequestService.counter++
        RequestService.loading$.next(true)
        return config
      },
      (error) => {
        RequestService.loading$.next(false)
        return Promise.reject(error)
      }
    )

    Axios.interceptors.response.use(
      (response) => {
        RequestService.counter--
        if (RequestService.counter === 0) {
          RequestService.loading$.next(false)
        }
        return response
      },
      (error) => {
        RequestService.counter--
        RequestService.loading$.next(false)
        if (error.response?.status !== 401) {
          let message = 'Es ist zu einem unerwarteten Serverfehler gekommen.'
          if (error.response && error.response.data && error.response.data.localizedMessage) {
            message = error.response.data.localizedMessage
          }
          if (error.response?.data) {
            const errorResponse = error.response.data
            let logMessage = errorResponse.error + ' - ' + errorResponse.localizedMessage
            if (errorResponse.trace) {
              logMessage += ':\n' + errorResponse.trace
            }
            // tslint:disable-next-line:no-console
            console.error(logMessage, errorResponse)
            // Validation error from backend
            if (errorResponse.errors) {
              message = errorResponse.errors[0].defaultMessage
            }
          }
          if (error && !error.toString().includes('Login required')) {
            RequestService.showError('Error', message)
          }
        }
        return Promise.reject(error)
      }
    )
  }
}

export default new RequestService()
