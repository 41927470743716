import moment from 'moment'
import { addLocale } from 'primereact/api'

export const DATE_FORMAT = 'DD.MM.YYYY'

export const parseDate = (dateAsString: string): Date | undefined => {
  const dateAsMoment = moment(dateAsString, DATE_FORMAT)
  if (dateAsMoment) {
    const dateValue = dateAsMoment.toDate()
    dateValue.setHours(12, 0, 0)
    return dateValue
  } else {
    return undefined
  }
}

export const isDate = (date: any): boolean => {
  return Object.prototype.toString.call(date) === '[object Date]'
}

export const formatDate = (date: Date): string | undefined => {
  const dateAsMoment = moment(date)
  if (dateAsMoment) {
    return dateAsMoment.format(DATE_FORMAT)
  } else {
    return undefined
  }
}

export const getDateInPast = (years: number): Date => {
  const date = new Date()
  date.setFullYear(date.getFullYear() - years)
  return date;
}

export const dateToIsoDate = (date: Date) => {
  date.setHours(12, 0, 0)
  return date.toISOString().substring(0, 10)
}

export const dateToISOString = (date: Date) => {
  if (date) {
    date.setHours(12, 0, 0)
    return date.toISOString()
  } else {
    return null
  }
}

export const isSameDate = (date1: Date, date2: Date) => {
  return dateToIsoDate(date1) === dateToIsoDate(date2)
}

export const calcDifferenceInDays = (date1: Date | null, date2: Date | null): number | null => {
  if (!date1 || !date2) {
    return null
  }
  return (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24)
}


export const addLocales = () => {
  addLocale('de', {
    firstDayOfWeek: 1,
    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    dayNamesShort: ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA'],
    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'F', 'Sa'],
    monthNames: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    monthNamesShort: [
      'JAN',
      'FEB',
      'MÄR',
      'APR',
      'MAI',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OKT',
      'NOV',
      'DEZ',
    ],
    today: 'Heute',
    clear: 'Zurücksetzen',
  })
  addLocale('en', {
    firstDayOfWeek: 1,
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    monthNamesShort: [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ],
    today: 'Today',
    clear: 'Reset',
  })
}
