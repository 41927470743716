export const navigations = [
  {
    name: 'Home',
    description: 'Home',
    type: 'link',
    icon: 'i-Home1',
    path: '/home',
  },
  {
    name: 'Stammdaten',
    description: 'Anlegen und Anpassen der folgenden Stammdaten',
    type: 'dropDown',
    icon: 'i-Library',
    sub: [
      {
        icon: 'i-Split-Horizontal-2-Window',
        name: 'Branchen',
        path: '/stammdaten/branche/auflisten',
        type: 'link',
      },
      {
        icon: 'i-Split-Horizontal-2-Window',
        name: 'Avalarten',
        path: '/stammdaten/buergschaftsart/auflisten',
        type: 'link',
      },
      {
        icon: 'i-Split-Horizontal-2-Window',
        name: 'Gerichtsstände',
        path: '/stammdaten/gerichtsstand/auflisten',
        type: 'link',
      },
      {
        icon: 'i-Split-Horizontal-2-Window',
        name: 'Avalgeber',
        path: '/stammdaten/versicherer/auflisten',
        type: 'link',
      },
      {
        icon: 'i-Split-Horizontal-2-Window',
        name: 'Kundendaten',
        path: '/firma/auflisten',
        type: 'link',
      },
      {
        icon: 'i-Split-Horizontal-2-Window',
        name: 'Ansprechpartner',
        path: '/stammdaten/ansprechpartner/auflisten',
        type: 'link',
      },
      {
        icon: 'i-Split-Horizontal-2-Window',
        name: 'Interne Mitarbeiter',
        path: '/stammdaten/interne-mitarbeiter/auflisten',
        type: 'link',
      },
    ],
  },
  {
    name: 'Avalanfragen',
    description: 'Übersicht neu beantragter Avale',
    type: 'link',
    icon: 'i-Computer-Secure',
    sub: [
      {
        icon: 'i-Receipt-4',
        name: 'Auflisten',
        path: '/buergschaftsanfrage/auflisten',
        type: 'link',
      },
      {
        icon: 'i-Receipt-4',
        name: 'Erstellen',
        path: '/buergschaftsanfrage/erstellen',
        type: 'link',
      },
    ],
  },
  {
    name: 'Avalbestand',
    description: 'Gesamtübersicht aller ausgegebenen Avale',
    type: 'link',
    icon: 'i-Upload-Window',
    sub: [
      {
        icon: 'i-Receipt-4',
        name: 'Auflisten',
        path: '/obligo/auflisten',
        type: 'link',
      },
      {
        icon: 'i-Receipt-4',
        name: 'Erstellen',
        path: '/obligo/erstellen',
        type: 'link',
      },
      {
        icon: 'i-Receipt-4',
        name: 'Importieren',
        path: '/obligo/importieren',
        type: 'link',
      },
    ],
  },
  {
    name: 'Empfangene Avale',
    description: 'Gesamtübersicht empfangener Avale',
    type: 'link',
    icon: 'i-Folder-Download',
    sub: [
      {
        icon: 'i-Receipt-4',
        name: 'Auflisten',
        path: '/incoming-aval/auflisten',
        type: 'link',
      },
      {
        icon: 'i-Receipt-4',
        name: 'Erstellen',
        path: '/incoming-aval/erstellen',
        type: 'link',
      },
      {
        icon: 'i-Receipt-4',
        name: 'Importieren',
        path: '/incoming-aval/importieren',
        type: 'link',
      },
    ],
  },
  {
    name: 'Projekt',
    description: 'Gesamtübersicht aller Projekte',
    type: 'link',
    icon: 'i-Receipt',
    sub: [
      {
        icon: 'i-Receipt-4',
        name: 'Auflisten',
        path: '/projekt/auflisten',
        type: 'link',
      },
      {
        icon: 'i-Receipt-4',
        name: 'Erstellen',
        path: '/projekt/erstellen',
        type: 'link',
      },
      {
        icon: 'i-Receipt-4',
        name: 'Importieren',
        path: '/projekt/importieren',
        type: 'link',
      },
    ],
  },
  {
    name: 'Kreditzusagen',
    description: 'Verwaltung der Avalkreditverträge',
    type: 'link',
    icon: 'i-Money1',
    sub: [
      {
        icon: 'i-Receipt-4',
        name: 'Auflisten',
        path: '/kreditzusagen/auflisten',
        type: 'link',
      },
      {
        icon: 'i-Receipt-4',
        name: 'Erstellen',
        path: '/kreditzusagen/erstellen',
        type: 'link',
      },
      {
        icon: 'i-Receipt-4',
        name: 'Konzernrahmen Erstellen',
        path: '/konzern-rahmen/erstellen',
        type: 'link',
      },
    ],
  },
  {
    name: 'Reports',
    description: 'Übersicht aller Berichte',
    type: 'dropDown',
    icon: 'i-Bar-Chart',
    sub: [
      {
        icon: 'i-Receipt-4',
        name: 'Gesamtobligen',
        path: '/berichte/gesamt-obligen',
        type: 'link',
      },
      {
        icon: 'i-Receipt-4',
        name: 'Aval nach Sublimiten',
        path: '/berichte/obligen-nach-sublimiten',
        type: 'link',
      },
      {
        icon: 'i-Receipt-4',
        name: 'Auftragswert',
        path: '/berichte/auftragswert',
        type: 'link',
      },
      {
        icon: 'i-Receipt-4',
        name: 'Prämien',
        path: '/berichte/praemien',
        type: 'link',
      },
      {
        icon: 'i-Receipt-4',
        name: 'Nutzerstatistik',
        path: '/berichte/nutzer-statistik',
        type: 'link',
      },
    ],
  },
]
