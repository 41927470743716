import './config-view.scss'
import React, { FC, useEffect, useState } from 'react'
import { Subscription } from 'rxjs'
import { Dropdown } from 'primereact/dropdown'
import localStorageService from '../services/localStorageService'
import { Card } from 'primereact/card'
import TranslateService from '../services/translate-service'
import PaginationInfo from '../models/pagination-info'

const ConfigView: FC = () => {

  const NOT_SET = TranslateService.translate("Nicht gesetzt")

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [rowsPerPage, setRowsPerPage] = useState<number | string>(NOT_SET)

  const addSub = (sub: Subscription) => {
    setSubscriptions([sub, ...subscriptions])
  }

  /**
   * Info Dialog configuration
   */
  useEffect(() => {
    const paginationInfo: PaginationInfo = localStorageService.getItem('pagination-info')
    if (paginationInfo) {
      setRowsPerPage(paginationInfo.rows)
    } else {
      setRowsPerPage(NOT_SET)
    }

    // clean up the subscriptions on-destroy
    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe())
    }
  }, [])

  return (
    <>
      <Card title={TranslateService.translate('Allgemein')}>
        <div className="p-field p-grid">
          <label htmlFor="rowsPerPage" className="p-col-fixed" style={{ width: '150px' }}>
            Rows per page
          </label>
          <div className="p-col">
            <Dropdown
              id="rowsPerPage"
              name={'field name'}
              value={rowsPerPage}
              options={[NOT_SET, 5, 10, 25, 50, 100]}
              onChange={(e) => {
                e.preventDefault()
                if (e.value) {
                  if (e.value === NOT_SET) {
                    localStorageService.removeItem('pagination-info')
                  } else {
                    localStorageService.setItem('pagination-info', { rows: e.value })
                  }
                  setRowsPerPage(e.value)
                }
              }}
            />
          </div>
        </div>
      </Card>
    </>
  )
}
export default ConfigView
