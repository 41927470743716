import React, { Component, Suspense } from 'react'
import { withRouter } from 'react-router-dom'
import Layout from './Layout'
import AppContext from 'app/appContext'

class LandingPage extends Component {
  state = {}

  render() {
    const { route } = this.props
    return (
      <Suspense>
        <Layout routes={route.routes} />
      </Suspense>
    )
  }
}

LandingPage.contextType = AppContext

export default withRouter(LandingPage)
