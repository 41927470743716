export const AUTH_ROLES = {
  ADMIN_MITARBEITER: ['AUTH_staff'],
  ADMIN_MITARBEITER_LIMITED: ['AUTH_staff:limited'],
  AUTHENTICATED: [
    'AUTH_staff',
    'AUTH_customer:full',
    'AUTH_customer:limited',
    'AUTH_customer:read',
  ],
  KUNDE: ['AUTH_customer:full', 'AUTH_customer:limited', 'AUTH_customer:read'],
  KUNDE_FULL: ['AUTH_customer:full'],
  KUNDE_LIMITED: ['AUTH_customer:limited'],
  KUNDE_READ_ONLY: ['AUTH_customer:read'],
  GUEST: ['ROLE_GUEST'],
}
