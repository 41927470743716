import { lazy } from 'react'
import GRoute from '../../../models/route'
import { AUTHORITIES } from '../../../auth/authorities'

const KonzernRahmenEdit = lazy(() => import('./konzern-rahmen-edit'))

const konzernRahmenRoutes: GRoute[] = [
  {
    path: '/konzern-rahmen/erstellen',
    component: KonzernRahmenEdit,
    auth: AUTHORITIES.KREDITZUSAGEN__ANLEGEN,
  },
  {
    path: '/konzern-rahmen/editieren/:id',
    component: KonzernRahmenEdit,
    auth: AUTHORITIES.KREDITZUSAGEN__EDITIEREN_OPTION,
  },
]

export default konzernRahmenRoutes
