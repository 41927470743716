import './konzern-select.scss'
import React, { useEffect, useState } from 'react'
import { Subscription } from 'rxjs'
import KundeService from '../features/kunde/kunde-service'
import FirmaKonzern from '../models/firma-konzern'
import localStorageService from '../services/localStorageService'
import { Indexable } from '../../types'
import TranslateService from '../services/translate-service'
import { isEqual } from 'lodash'
import { Dropdown } from 'primereact/dropdown'

const KonzernSelect = () => {

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [firmaKonzernList, setFirmaKonzernList] = useState<FirmaKonzern[]>([])
  const [selectedFirmaKonzern, setSelectedFirmaKonzern] = useState<FirmaKonzern | undefined>(undefined)
  const [language, setLanguage] = useState<string | undefined>()

  const addSub = (sub: Subscription) => {
    setSubscriptions([sub, ...subscriptions])
  }

  useEffect(() => {
    const currentKonzernId = getCurrentKonzernFromLocalStorage()
    addSub(
      KundeService.getFirmaKonzernList().subscribe((response: any) => {
        let _firmaKonzernList: FirmaKonzern[] = response.data
        let _selectedFirmaKonzern: FirmaKonzern | undefined = undefined
        if (_firmaKonzernList) {
          _firmaKonzernList.unshift({
            id: -1,
            firmaName: `${TranslateService.translate('Alle')}`,
          })
          for (const firmaKonzern of _firmaKonzernList) {
            firmaKonzern.display = itemDisplay(firmaKonzern)
            if (currentKonzernId && firmaKonzern.id === currentKonzernId) {
              _selectedFirmaKonzern = firmaKonzern
            }
            if (!firmaKonzern.display) {
              console.log('Firmennamen fehlt: ', firmaKonzern)
            }
          }
        }

        _firmaKonzernList = _firmaKonzernList.filter(firmaKonzern => firmaKonzern.display !== undefined)

        setFirmaKonzernList(_firmaKonzernList)
        setSelectedFirmaKonzern(_selectedFirmaKonzern)
        populateSelectedKundenIds(_selectedFirmaKonzern, _firmaKonzernList)
      }),
    )
  }, [language])

  useEffect(() => {
    addSub(
      TranslateService.language$.subscribe((_language: string) => {
        if (_language) {
          setLanguage(_language)
        }
      }),
    )
  }, [])

  const getCurrentKonzernFromLocalStorage: () => number = () => {
    const item: Indexable = localStorageService.getItem('current_konzern', undefined)
    if (item) {
      return item.current_konzern
    }
    return item
  }


  const itemDisplay = (item: FirmaKonzern) => {
    let display: string = item.firmaName
    if (item?.konzern) {
      display += ' (' + item.konzern.name + ')'
    }
    //console.log(display, item)
    return display
  }

  /*	const itemTemplate = (item: any) => {
      return (<div>{item.display}</div>)
    }*/

  const onFirmaKonzernSelect = (e: any) => {
    const _selectedFirmaKonzern: FirmaKonzern = e.value
    if (!_selectedFirmaKonzern || isEqual(selectedFirmaKonzern, _selectedFirmaKonzern)) {
      return
    }
    setSelectedFirmaKonzern(_selectedFirmaKonzern)
    populateSelectedKundenIds(_selectedFirmaKonzern, firmaKonzernList)
    localStorageService.setItem('current_konzern', { 'current_konzern': _selectedFirmaKonzern?.id })
  }

  const populateSelectedKundenIds = (selectedFirmaKonzern: FirmaKonzern | undefined, firmaKonzerne: FirmaKonzern[]) => {

    if (!selectedFirmaKonzern) {
      KundeService.setCurrentKonzerne([])
      return
    }

    const selectedKundenIds: number[] = []

    let konzernId = selectedFirmaKonzern.konzern ? selectedFirmaKonzern.konzern.id : selectedFirmaKonzern.id
    for (const firmaKonzern of firmaKonzerne) {
      if (firmaKonzern.konzern && firmaKonzern.konzern.id === konzernId) {
        selectedKundenIds.push(firmaKonzern.id)
      }
    }

    selectedKundenIds.push(konzernId)

    KundeService.setCurrentKonzerne(selectedKundenIds)
  }

  return (
    <>
      <Dropdown value={selectedFirmaKonzern}
                onChange={onFirmaKonzernSelect}
                options={firmaKonzernList}
                optionLabel='display'
                placeholder={TranslateService.translate('Kundenauswahl')}
                filter
                style={{ width: '400px' }}
      />
    </>
  )
}

export default KonzernSelect
