import axios from 'axios'
import localStorageService from './localStorageService'
import Axios from 'axios-observable'
import { User } from '../models/User'
import { BehaviorSubject } from 'rxjs'

class AuthService {
  user: User = undefined

  public user$: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined)

  retrieveRoles() {
    return Axios.get('user/roles', {
      params: {},
    })
  }

  logout = () => {
    this.setSession(null)
    this.removeUser()
  }

  setSession = (token: string | null) => {
    if (token) {
      localStorage.setItem('jwt_token', token)
      Axios.defaults.headers.common.Authorization = 'Bearer ' + token
      Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
      axios.defaults.headers.common.Authorization = 'Bearer ' + token
    } else {
      localStorage.removeItem('jwt_token')
      delete axios.defaults.headers.common.Authorization
    }
  }

  setUser = (user: User) => {
    this.user$.next(user)
    localStorageService.setItem('auth_user', user)
  }

  removeUser = () => {
    this.user$.next(undefined)
    localStorage.removeItem('auth_user')
  }

  hasAuthority: (authority: string) => boolean = (authority) => {
    const userRoles: string[] = localStorageService.getItem('user_roles')
    if (!userRoles) {
      return false
    }
    return userRoles.some((r) => 'AUTH_' + authority === r)
  }

  storePath = (path: string) => {
    localStorageService.setItem('stored_path', path)
  }

  // todo improve
  retrieveStoredPath: () => string = () => {
    // const storedPath = localStorageService.getItem('stored_path', '/home')
    // localStorage.removeItem('stored_path')
    return '/home'
  }
}

export default new AuthService()
