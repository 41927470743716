import React, { useEffect, useState } from 'react'
import AuthService from '../services/auth-service'
import { AUTHORITIES } from '../auth/authorities'
import TranslateService from '../services/translate-service'
import { Subscription } from 'rxjs'

const Footer = () => {
  const [language, setLanguage] = useState<string | undefined>()
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])

  const addSub = (sub: Subscription) => {
    setSubscriptions([sub, ...subscriptions])
  }

  useEffect(() => {
    addSub(
      TranslateService.language$.subscribe((_language: string) => {
        if (_language) {
          TranslateService.language = _language
          setTimeout(() => setLanguage(_language), 0)
        }
      })
    )
  }, [language])

  if (!AuthService.hasAuthority(AUTHORITIES.FOOTER)) {
    return (
      <div>
        {TranslateService.translate(
          'Sie haben nicht die erforderlichen Zugriffsrechte für diese Seite.'
        )}
      </div>
    )
  }
  return (
    <div className="app-footer">
      <div className="footer-text">
        {TranslateService.translate(
          '© 2024 Gracher Kredit- und Kautionsmakler. Alle Rechte vorbehalten.'
        )}
      </div>
    </div>
  )
}

export default Footer
