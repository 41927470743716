import { SyntheticEvent } from 'react'
import { AvalStatus } from '../models/aval-status'

export const doNothing = () => {}

export const htmlToString = (html: string) => {
  const tempDivElement = document.createElement('div')
  tempDivElement.innerHTML = html
  return tempDivElement.textContent || tempDivElement.innerText || ''
}

export const hasValue = (value: string) => {
  return value !== undefined && value !== null && value !== ''
}

export const beforeActive = (status: string) => {
  return (
    status === AvalStatus.ANFRAGE || status === AvalStatus.PLANNED || status === AvalStatus.DRAFT
  )
}

export const isNumeric = (str: any) => {
  return (
    // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)
    !isNaN(str) &&
    // ensure strings of whitespace fail
    !isNaN(parseFloat(str))
  )
}

export const isStringNumeric = (value: string | number) => {
  if (typeof value === 'number') {
    return true
  }
  // just process strings
  if (typeof value !== 'string') {
    return false
  }
  return (
    // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    // @ts-ignore
    !isNaN(value) &&
    // ensure strings of whitespace fail
    !isNaN(parseFloat(value))
  )
}

export const isString = (value: any) => {
  return typeof value === 'string'
}

export const isStringNumericRegex = (str: string) => {
  return str.match(/^-?\d+$/) || str.match(/^\d+\.\d+$/)
}

const booleanTrueValue = ['Ja', 'Yes', 'true']
const booleanFalseValues = ['Nein', 'No', 'false']

export const isBooleanString = (str: string) => {
  if (!str) {
    return false
  }
  return (
    booleanTrueValue.some((booleanValue) => booleanValue.toLowerCase() === str.toLowerCase()) ||
    booleanFalseValues.some((booleanValue) => booleanValue.toLowerCase() === str.toLowerCase())
  )
}

export const isTrue = (str: string): boolean => {
  if (!str) {
    return false
  }
  return booleanTrueValue.some((booleanValue) => booleanValue.toLowerCase() === str.toLowerCase())
}

export const mapSlashToDot = (event: SyntheticEvent) => {
  event.target.value = event.target.value?.replaceAll('/', '.')
}

export const onlyUnique = (value: any, index: number, self: any) => {
  return self.indexOf(value) === index
}

export const isEmpty = (arr: any[] | undefined) => {
  return !arr || arr.length === 0
}

export const jsonToFormData = (data: any) => {
  const formData = new FormData()
  const jsonBlob = new Blob([JSON.stringify(data)], { type: 'application/json' })
  formData.append('request', jsonBlob)
  return formData
}

export const toMultipart = (files: File[]): FormData => {
  const formData = new FormData()
  if (files) {
    files.forEach((file) => {
      formData.append('files', file)
    })
  }
  return formData
}

export const appendFilesToFormData = (
  formData: FormData,
  files: File[],
  requestPartName: string = 'sondertexte'
): void => {
  if (files !== undefined) {
    files.forEach((file) => {
      formData.append(requestPartName, file)
    })
  }
}

export const toMultipartFormData = (
  jsonRequest: any,
  files: File[],
  requestPartName?: string
): FormData => {
  const formData = jsonToFormData(jsonRequest)
  appendFilesToFormData(formData, files, requestPartName)
  return formData
}

export const notNullOr = (arg: any, defaultValue: any) => {
  return arg ? arg : defaultValue
}

export const mapEnumToSelect = (enumObject: Record<string, string>) => {
  return Object.keys(enumObject).map((key) => {
    return {
      label: key,
      value: enumObject[key],
    }
  })
}

export const compareVersion = (version1: string, version2: string) => {
  const _version1 = versionToNumber(version1)
  const _version2 = versionToNumber(version2)
  if (_version1 > _version2) {
    return 1
  } else if (_version1 < _version2) {
    return -1
  } else {
    return 0
  }
}

export const versionToNumber: (_version: string) => string = (_version) => {
  const versionAsString = _version
    .split('.')
    .map((part: string) => parseInt(part) + 100000)
    .join('')
  return versionAsString
}
