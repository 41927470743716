import { GRoute } from '../../models/Route'
import { lazy } from 'react'
import { AUTHORITIES } from '../../auth/authorities'

const Auftragswert = lazy(() => import('./auftragswert/auftragswert-list'))
const GesamtObligo = lazy(() => import('./gesamtobligo/gesamtobligo-list'))
const NutzerStatistic = lazy(() => import('./nutzer-statistic/nutzer-statistic-list'))
const ObligoNachSublimit = lazy(() => import('./obligo-nach-sublimit/obligo-nach-sublimit-list'))

const reportRoutes: GRoute[] = [
  {
    path: '/berichte/auftragswert',
    component: Auftragswert,
    auth: AUTHORITIES.REPORTS__AUFTRAGSWERT_LISTE,
  },
  {
    path: '/berichte/gesamt-obligen',
    component: GesamtObligo,
    auth: AUTHORITIES.REPORTS__MEINE_GESAMTOBLIGO_LISTE,
  },
  {
    path: '/berichte/nutzer-statistik',
    component: NutzerStatistic,
    auth: AUTHORITIES.REPORTS__NUTZERSTATISTIK_LISTE,
  },
  {
    path: '/berichte/obligen-nach-sublimiten',
    component: ObligoNachSublimit,
    auth: AUTHORITIES.REPORTS__MEINE_SUBLIMITS_LISTE,
  },
]
export default reportRoutes
