import { combineReducers } from 'redux'
import LoginReducer from './LoginReducer'
import UserReducer from './UserReducer'
import NotificationReducer from './NotificationReducer'
import LayoutReducer from './LayoutReducer'

const RootReducer = combineReducers({
  layout: LayoutReducer,
  login: LoginReducer,
  user: UserReducer,
  notification: NotificationReducer,
})

export default RootReducer
