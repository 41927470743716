const layoutSettings = {
  leftSidebar: {
    theme: 'sidebar-gracher-dark',
  },
  header: {
    show: true,
  },
  searchBox: {
    open: false,
  },
  secondarySidebar: { show: false },
}

export default layoutSettings
