import React, { FC, useCallback, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { navigations } from '../navigations'
import { merge } from 'lodash'
import { classList } from 'app/utils/@utils'
import Scrollbar from 'react-perfect-scrollbar'
import DropDownMenu from './components/DropDownMenu'

import { useDispatch, useSelector } from 'react-redux'

import { getRolesForRoute } from '../root-routes'
import AuthService from '../services/auth-service'
import { setLayoutSettings } from '../redux/actions/LayoutActions'
import TranslateService from "../services/translate-service";

const Sidenav: FC = () => {
  const [selectedItem, setSelectedItem] = useState({})
  const settings = useSelector((state) => state.layout.settings)
  const dispatch = useDispatch()
  const location = useLocation()

  // todo possible improvement
  const findSelectedItem = useCallback(() => {
    navigations.forEach((item) => {
      if (item.sub) {
        item.sub.forEach((child) => {
          if (child.sub) {
            child.sub.forEach((grandChild) => {
              if (grandChild.path === location.pathname) {
                setSelectedItem(item)
              }
            })
          } else {
            if (child.path === location.pathname) {
              setSelectedItem(item)
            }
          }
        })
      } else {
        if (item.path === location.pathname) {
          setSelectedItem(item)
        }
      }
    })
  }, [location.pathname])

  useEffect(() => {
    findSelectedItem()
  }, [location, findSelectedItem])

  const closeSecNav = useCallback(() => {
    const other = {}

    other.open = false
    // if (isMobile()) {
    //   other.open = false;
    // }

    dispatch(
      setLayoutSettings(
        merge({}, settings, {
          layoutSettings: {
            leftSidebar: {
              ...other,
              secondaryNavOpen: false,
            },
          },
        })
      )
    )
  }, [dispatch])

  const openSecNav = useCallback(() => {
    dispatch(
      setLayoutSettings(
        merge({}, settings, {
          layoutSettings: {
            leftSidebar: {
              open: true,
              secondaryNavOpen: true,
            },
          },
        })
      )
    )
  }, [dispatch])

  useEffect(() => {
    if (window.innerWidth < 1200) {
      closeSecNav()
    } else {
      openSecNav()
    }

    const windowListener: any = window.addEventListener('resize', () => {
      if (window.innerWidth < 1200) {
        closeSecNav()
      } else {
        openSecNav()
      }
    })

    return () => {
      window.removeEventListener('resize', windowListener)
    }
  }, [openSecNav, closeSecNav])

  const onMainItemMouseEnter = (item) => {
    setSelectedItem(item)
  }

  const onMainItemMouseLeave = () => {}

  const displayParent = (parent: any) => {
    if (!parent.sub) {
      return display(parent)
    }
    const _displayParent = parent.sub.some((child: any) => {
      return display(child)
    })

    if (_displayParent) {
      parent.subToDisplay = subItemsToDisplay(parent.sub)
    }
    return _displayParent
  }

  const display = (child: any) => {
    const routeRoles = getRolesForRoute(child.path)
    return AuthService.hasAuthority(routeRoles)
  }

  const subItemsToDisplay = (children: any[]) => {
    return children.filter((child) => display(child))
  }

  return (
    <div className="side-content-wrap">
      <Scrollbar
        className={classList({
          'sidebar-left o-hidden rtl-ps-none': true,
          open: settings.layoutSettings.leftSidebar.open,
        })}
      >
        <ul className="navigation-left">
          {navigations.map(
            (item, i) =>
              displayParent(item) && (
                <li
                  className={classList({
                    'nav-item': true,
                    active: item.name === selectedItem.name,
                  })}
                  onMouseEnter={() => {
                    onMainItemMouseEnter(item)
                  }}
                  onMouseLeave={onMainItemMouseLeave}
                  key={i}
                >
                  {item.path && item.type !== 'extLink' && (
                    <NavLink className="nav-item-hold" to={item.path}>
                      <i className={`im nav-icon ${item.icon}`} />
                      <span className="nav-text">{TranslateService.translate(item.name)}</span>
                    </NavLink>
                  )}
                  {item.path && item.type === 'extLink' && (
                    <a className="nav-item-hold" href={item.path}>
                      <i className={`im nav-icon ${item.icon}`} />
                      <span className="nav-text">{TranslateService.translate(item.name)}</span>
                    </a>
                  )}
                  {!item.path && (
                    <div className="nav-item-hold">
                      <i className={`im nav-icon ${item.icon}`} />
                      <span className="nav-text">{TranslateService.translate(item.name)}</span>
                    </div>
                  )}
                  <div className="triangle" />
                </li>
              )
          )}
        </ul>
      </Scrollbar>

      <Scrollbar
        className={classList({
          'sidebar-left-secondary o-hidden rtl-ps-none': true,
          open: settings.layoutSettings.leftSidebar.secondaryNavOpen,
        })}
      >
        <i className="im sidebar-close i-Close" onClick={closeSecNav} />
        <div className="logo ml-4 mb-4">
          <img src="/assets/images/logo.png" alt="logo" />
        </div>
        <div className="header mb-3 ml-4">
          <h5 className="font-weight-bold mb-1">{TranslateService.translate(selectedItem.name)}</h5>
          <p>{TranslateService.translate(selectedItem.description)}</p>
        </div>

        {selectedItem && selectedItem.subToDisplay && (
          <DropDownMenu menu={selectedItem.subToDisplay} closeSecSidenav={closeSecNav} />
        )}
      </Scrollbar>
      <div
        className={classList({
          'sidebar-overlay': true,
          open: settings.layoutSettings.leftSidebar.secondaryNavOpen,
        })}
      />
    </div>
  )
}

export default Sidenav
