import { lazy } from 'react'
import { AUTHORITIES } from '../../auth/authorities'

const Home = lazy(() => import('./home'))

const homeRoutes = [
  {
    path: '/home',
    component: Home,
    auth: AUTHORITIES.HOME__LINK,
  },
]

export default homeRoutes
