import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { DateTime } from 'luxon'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: 'de',
    interpolation: {
      format: (value, format, lng) => {
        if (value instanceof Date) {
          return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format])
        }
        return value
      },
    },
  })

export const i18Init = () => {
  // it does nothing
}
