import { ReplaySubject, Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

class WindowService {
  private height$: Subject<number> = new ReplaySubject<number>()

  public setHeight(value: number) {
    this.height$.next(value)
  }

  public getHeight() {
    return this.height$.pipe(debounceTime(1000))
  }
}

export default new WindowService()
