import React, { useEffect } from 'react'
import RequestService from '../services/request-service'
import { Toast } from 'primereact/toast'
import { useToast } from './toast-context'
import { useAuth0 } from '@auth0/auth0-react'

const XToast = () => {
  const { toast, showError } = useToast()
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    RequestService.setToastMethod(showError, loginWithRedirect)
  }, [])

  return <Toast ref={toast} position="bottom-right" />
}

export default XToast
