import { lazy } from 'react'
import GRoute from '../../../models/route'
import { AUTHORITIES } from '../../../auth/authorities'

const IncomingAvalEdit = lazy(() => import('./incoming-aval-edit'))
const IncomingAvalList = lazy(() => import('./incoming-aval-list'))
const IncomingAvalImport = lazy(() => import('./incoming-aval-import'))

const incomingAvalRoutes: GRoute[] = [
  {
    path: '/incoming-aval/erstellen',
    component: IncomingAvalEdit,
    auth: AUTHORITIES.INCOMING_AVAL__ANLEGEN,
  },
  {
    path: '/incoming-aval/edit/:id',
    component: IncomingAvalEdit,
    auth: AUTHORITIES.INCOMING_AVAL__EDITIEREN_OPTION,
  },
  {
    path: '/incoming-aval/importieren',
    component: IncomingAvalImport,
    auth: AUTHORITIES.INCOMING_AVAL__IMPORTIEREN,
  },
  {
    path: '/incoming-aval/auflisten',
    component: IncomingAvalList,
    auth: AUTHORITIES.INCOMING_AVAL__MEINE_LISTE,
  },
]

export default incomingAvalRoutes
