import { BehaviorSubject } from 'rxjs'
import Axios from 'axios-observable'
import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'
import ServerInfo from './server-info'

class InfoService {
  public displayInfoDialog$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  displayInfoDialog = () => {
    this.displayInfoDialog$.next(true)
  }

  hideInfoDialog = () => {
    this.displayInfoDialog$.next(false)
  }

  loadBackendInfo: () => AxiosObservable<ServerInfo> = () => {
    return Axios.get<ServerInfo>('/info/build')
  }
}

export default new InfoService()
