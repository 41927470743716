import { FC, useEffect, useState } from 'react'
import i18n from 'i18next'
import { SelectButton } from 'primereact/selectbutton'
import './language-switcher.scss'
import classNames from 'classnames'
import TranslateService from '../services/translate-service'

type LanguageOption = {
  name: string
  code: string
}

const LanguageSwitcherSelector: FC = () => {
  const defaultLanguage = 'de'
  const [currentLanguage, setCurrentLanguage] = useState<string>(defaultLanguage)

  const justifyOptions: LanguageOption[] = [
    { name: 'English', code: 'en' },
    { name: 'Deutsch', code: 'de' },
  ]

  const justifyTemplate = (option: LanguageOption) => {
    return (
      <div
        className={classNames(
          currentLanguage === option.code ? 'selected-option' : '',
          'language-option'
        )}
      >
        <div className={option.code} />
      </div>
    )
  }

  useEffect(() => {
    let _currentLanguage = i18n.language
    if (_currentLanguage !== 'en' && _currentLanguage !== 'de') {
      _currentLanguage = defaultLanguage
    }
    i18n.changeLanguage(_currentLanguage)
    setCurrentLanguage(_currentLanguage)
  }, [])

  const onLanguageChange = (e) => {
    const option = e.value
    console.log('Language changed to ', e.value)
    setCurrentLanguage(option.code)
    i18n.changeLanguage(option.code)
    TranslateService.language$.next(i18n.language)
  }

  return (
    <div className={'language-selector'}>
      <SelectButton
        value={currentLanguage}
        options={justifyOptions}
        onChange={onLanguageChange}
        itemTemplate={justifyTemplate}
      />
    </div>
  )
}
export default LanguageSwitcherSelector
