import { lazy } from 'react'
import { AUTH_ROLES } from 'app/auth/authRoles'
import { GRoute } from '../../../models/Route'
import {AUTHORITIES} from "../../../auth/authorities";

const InterneMitarbeiterEdit = lazy(() => import('./mitarbeiter-edit'))
const InterneMitarbeiterList = lazy(() => import('./mitarbeiter-list'))

const mitarbeiterRoutes: GRoute[] = [
  {
    path: '/stammdaten/interne-mitarbeiter/erstellen',
    component: InterneMitarbeiterEdit,
    auth: AUTHORITIES.STAMMDATEN__INTERNMITARBEITER_ANLEGEN_MAINBUTTON,
  },
  {
    path: '/stammdaten/interne-mitarbeiter/editieren/:id',
    component: InterneMitarbeiterEdit,
    auth: AUTHORITIES.STAMMDATEN__INTERNMITARBEITER_EDITIEREN_OPTION,
  },
  {
    path: '/stammdaten/interne-mitarbeiter/auflisten',
    component: InterneMitarbeiterList,
    auth: AUTHORITIES.STAMMDATEN__INTERNMITARBEITER_LISTE,
  },
]

export default mitarbeiterRoutes
